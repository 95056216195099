import styles from "./ActionButtons.module.css";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import NavMenuButton from "./NavMenuButton";
import OpenFormButton from "./OpenFormButton";
import CloseFormButton from "./CloseFormButton";
import LoginForm from "./LoginForm";
import InboxButton from "./InboxButton";
import UserButton from "./UserButton";
import CartButton from "./CartButton";
import FavoritesButton from "./FavoritesButton";

export default function ActionButtons() {
  const { user } = useContext(AppContext);

  return (
    <div className={styles.actionButtons}>
      <CartButton />
      <FavoritesButton />
      {!user ? (
        <>
          <LoginForm />
          <CloseFormButton />
          <OpenFormButton />
        </>
      ) : (
        <>
          <InboxButton />
          <UserButton />
        </>
      )}
      <NavMenuButton />
    </div>
  );
}
