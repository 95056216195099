import styles from "./Order.module.css";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { OrderContext } from "../../context/OrderContext";
import Cards from "./Cards";
import Description from "./Description";
import OrderForm from "./OrderForm";

export default function Order() {
  const { totalPrice } = useContext(AppContext);

  return (
    <OrderContext.Provider value={{}}>
      <main className={styles.main}>
        <h1>Bestilling</h1>
        <div className={styles.products}>
          <div className={styles.cardsContainer}>
            <Cards />
            <div className={styles.totalPrice}>
              <p>Totalt:</p>
              <p>{totalPrice} NOK</p>
            </div>
          </div>
          <Description />
        </div>
        <OrderForm />
      </main>
    </OrderContext.Provider>
  );
}
