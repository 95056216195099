import styles from "./SupportButton.module.css";
import { useContext } from "react";
import { InboxContext } from "../../context/InboxContext";

export default function SupportButton() {
  const { setMailDeletes, toggleSupport, setToggleOrders, setToggleSupport, supportMailsUnRead } = useContext(InboxContext);
  function showSupport() {
    setToggleOrders(false);
    setToggleSupport(true);
    setMailDeletes([]);
  }
  return (
    <div className={`${styles.supportButton} ${toggleSupport ? styles.active : ""}`} onClick={() => showSupport()}>
      Support
      {!supportMailsUnRead <= 0 ? <p className={styles.supportEmailAmount}>{supportMailsUnRead}</p> : ""}
    </div>
  );
}
