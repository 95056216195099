import styles from "./CheckBox.module.css";
import { useContext } from "react";
import { InboxContext } from "../../context/InboxContext";

export default function CheckBox(props) {
  const { mailDeletes, setMailDeletes } = useContext(InboxContext);

  function checkMail(event, mail) {
    event.stopPropagation();
    let newMailDelets = mailDeletes;

    const checked = event.target.checked;

    if (!checked) {
      const filterdDelets = newMailDelets.filter((dMail) => dMail.id !== mail.id);
      setMailDeletes(filterdDelets);
      return;
    }

    if (checked) {
      setMailDeletes([...newMailDelets, mail]);
    }
  }

  return <input className={styles.checkBox} type="checkbox" onClick={(event) => checkMail(event, props.mail)} />;
}
