import styles from "./DummyCards.module.css";
import { useState, useEffect } from "react";

export default function DummyCards() {
  const [dummyProducts, setDummyProducts] = useState([]);

  useEffect(() => {
    let amount = 12;
    let newDummyProducts = [];

    for (let i = 0; i < amount; i++) {
      newDummyProducts.push({ id: i, data: {} });
    }

    setDummyProducts(newDummyProducts);
  }, []);

  return (
    <div className={styles.cards}>
      {dummyProducts.map((product) => {
        return (
          <div key={product.id} className={styles.card}>
            <div className={styles.dummyImage}></div>
            <div className={styles.dummyContent}></div>
            <div className={styles.dummyContent}></div>
            <div className={styles.dummyContent}></div>
            <div className={styles.dummyContent}></div>
          </div>
        );
      })}
    </div>
  );
}
