import DisplayMessage from "../DisplayMessage/DisplayMessage";

export default function validation(event, args) {
  const { setTitleError, setDescriptionError, setPriceError, setStockError, setDiscountError, setMessage, formType } = args;
  const productImg = event.target.productImgInput.files[0];
  const titlevalue = event.target.title.value.trim();
  const descriptionValue = event.target.description.value.trim();
  const priceValue = event.target.price.value.trim();
  const stockValue = event.target.stock.value.trim();
  const discountValue = event.target.discount.value;

  if (titlevalue.length <= 0) {
    setTitleError(true);
  } else {
    setTitleError(false);
  }

  if (descriptionValue.length <= 0) {
    setDescriptionError(true);
  } else {
    setDescriptionError(false);
  }

  if (priceValue.length <= 0) {
    setPriceError(true);
  } else {
    setPriceError(false);
  }

  if (stockValue.length <= 0) {
    setStockError(true);
  } else {
    setStockError(false);
  }

  if (discountValue.length <= 0) {
    setDiscountError(true);
  } else {
    setDiscountError(false);
  }

  if (formType === "add" && !productImg) {
    setMessage(<DisplayMessage messageType="warning">Vennligst fyll ut alle felt</DisplayMessage>);
    return false;
  }

  if (titlevalue.length <= 0 || descriptionValue.length <= 0 || priceValue.length <= 0 || stockValue.length <= 0 || discountValue.length <= 0) {
    setMessage(<DisplayMessage messageType="warning">Vennligst fyll ut alle felt</DisplayMessage>);
    return false;
  }

  return true;
}
