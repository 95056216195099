import styles from "./AddProduct.module.css";
import ProductForm from "../common/ProductForm/ProductForm";

export default function AddProduct() {
  return (
    <main className={styles.main}>
      <ProductForm formType="add" />
    </main>
  );
}
