import styles from "./AddToCartButton.module.css";
import { useContext, useState } from "react";
import { AppContext } from "../../../../context/AppContext";
import addToCart from "../../../../actions/addToCart";
import Tooltip from "../../Tooltip/Tooltip";

export default function AddToCartButton(props) {
  const { cart, setCart, setShowCart } = useContext(AppContext);
  const [showTooltip, setShowTooltip] = useState(false);

  function handleAddToCart(event) {
    const itemAdded = addToCart(event, props.product, cart, setCart, setShowCart);

    if (!itemAdded) {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 3000);
    }
  }

  return (
    <div className={styles.buttonContainer}>
      {showTooltip ? (
        <div className={styles.tooltopContainer}>
          <Tooltip arrow="default" messageType="normal">
            Produktet er i handlekurven
          </Tooltip>
        </div>
      ) : (
        ""
      )}

      <div className={styles.button} onClick={(event) => handleAddToCart(event)}>
        Legg i handlekurv
      </div>
    </div>
  );
}
