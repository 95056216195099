import styles from "./EditHomeForm.module.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import submit from "./submit";
import ButtonLoader from "../common/Loaders/ButtonLoader";

export default function EditHomeForm(props) {
  const { setH1Value, setDescriptionValue, content, setToggleForm, previewUrl, setPreviewUrl } = props;
  const [h1Error, setH1Error] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  function handleChange(event) {
    const file = event.target.files[0];
    if (!file) return;

    const url = URL.createObjectURL(file);

    setPreviewUrl(url);
  }

  function handleCancle() {
    setToggleForm(false);
    setPreviewUrl("");
    setH1Value(content.h1);
    setDescriptionValue(content.description);
  }

  return (
    <form className={styles.form} onSubmit={(event) => submit(event, { content, setMessage, setH1Error, setDescriptionError, setLoading })}>
      <div className={styles.closeFormButton}></div>
      <label id={styles.homeImageContainer} htmlFor="bannerImage">
        {previewUrl || content.bannerUrl ? (
          <div className={styles.imgContainer}>
            <img src={previewUrl ? previewUrl : content.bannerUrl} />
          </div>
        ) : (
          <div className={styles.addImageButton}>
            <FontAwesomeIcon icon={faPlus} />
          </div>
        )}
      </label>
      <input className={styles.fileInput} type="file" name="bannerImage" id="bannerImage" onChange={(event) => handleChange(event)} />

      {message ? <div className={styles.messageContainer}>{message}</div> : ""}
      <label htmlFor="h1">Overskrift</label>
      {h1Error ? <p className={styles.error}>Skriv en overskrift</p> : ""}
      <input placeholder="OverSkrift" type="text" name="h1" id="h1" defaultValue={content.h1} onChange={(event) => setH1Value(event.target.value)} />

      <label htmlFor="description">Beskrivelse</label>
      {descriptionError ? <p className={styles.error}>Skriv en beskrivelse</p> : ""}
      <textarea placeholder="Beskrivelse" type="text" name="description" id="description" defaultValue={content.description} onChange={(event) => setDescriptionValue(event.target.value)} />

      <button>{loading ? <ButtonLoader /> : "Oppdater"}</button>
      <div className={styles.cancleButton} onClick={() => handleCancle()}>
        Avbryt
      </div>
    </form>
  );
}
