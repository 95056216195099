import styles from "./AddtoCartButton.module.css";
import { useState, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { ProductDetailsContext } from "../../context/ProductDetailsContext";
import addToCart from "../../actions/addToCart";
import Tooltip from "../common/Tooltip/Tooltip";
export default function AddtoCartButton() {
  const { cart, setCart, setShowCart } = useContext(AppContext);
  const { product } = useContext(ProductDetailsContext);
  const [showTooltip, setShowTooltip] = useState(false);

  function handleAddToCart(event) {
    const itemAdded = addToCart(event, product, cart, setCart, setShowCart);

    if (!itemAdded) {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 3000);
    }
  }
  return (
    <div className={styles.buttonContainer}>
      {showTooltip ? (
        <div className={styles.tooltopContainer}>
          <Tooltip arrow="default" messageType="normal">
            Produktet er i handlekurven
          </Tooltip>
        </div>
      ) : (
        ""
      )}
      <div className={styles.addToCartButton} onClick={(event) => handleAddToCart(event)}>
        Legg i handlekurv
      </div>
    </div>
  );
}
