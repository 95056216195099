import styles from "./EditButton.module.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
export default function EditButton(props) {
  return (
    <Link to={`/rediger-galleri/${props.gallery.id}`}>
      <div className={styles.editButton}>
        <FontAwesomeIcon icon={faPenToSquare} />
      </div>
    </Link>
  );
}
