import styles from "./AboutForm.module.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import submit from "./submit";
import Materials from "./Materials";
import ButtonLoader from "../common/Loaders/ButtonLoader";

export default function AboutForm(props) {
  const [previewUrl, setPreviewUrl] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  function createPreviewUrl(event) {
    const url = URL.createObjectURL(event.target.files[0]);
    setPreviewUrl(url);
  }

  return (
    <form className={styles.form} onSubmit={(event) => submit(event, setMessage, props.content, setLoading)}>
      <label htmlFor="imageInput" className={styles.imgContainer}>
        {previewUrl || props.content.url ? (
          <>
            <img src={!previewUrl ? props.content.url : previewUrl} />
            <div className={styles.editButton}>
              <FontAwesomeIcon icon={faPenToSquare} />
            </div>
          </>
        ) : (
          <div className={styles.plussIcon}>
            <FontAwesomeIcon icon={faPlus} />
          </div>
        )}
      </label>
      <input className={styles.imageInput} type="file" accept="image/*" name="aboutImage" id="imageInput" onChange={(event) => createPreviewUrl(event)} />
      {message ? <div className={styles.messageContainer}>{message}</div> : ""}

      <label htmlFor="aboutText" className={styles.labelInput}>
        Beskrivelse
      </label>
      <textarea name="aboutText" id="aboutText" defaultValue={props.content.text}></textarea>

      <Materials />
      <div className={styles.buttonsContainer}>
        <button type="submit">{!loading ? "Oppdater " : <ButtonLoader />}</button>
        <div className={styles.cancleButton} onClick={() => props.setToggleForm(false)}>
          Avbryt
        </div>
      </div>
    </form>
  );
}
