import styles from "./Cards.module.css";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import CartCard from "../Cards/CartCard/CartCard";

export default function Cards() {
  const { cart } = useContext(AppContext);

  return (
    <>
      <div className={styles.cards}>
        {cart.map((item) => {
          return (
            <div key={item.id}>
              <CartCard item={item} cardType="small" />
            </div>
          );
        })}
      </div>
    </>
  );
}
