import styles from "./DeleteButton.module.css";
import { useContext } from "react";
import { database } from "../../settings/settings";
import { ref, remove, update, get } from "firebase/database";
import { InboxContext } from "../../context/InboxContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default function DeleteButton() {
  const { mailDeletes, setMailDeletes } = useContext(InboxContext);
  const mailLengthsRef = ref(database, "inbox/mailLengths");

  async function deleteMail() {
    for (const mail of mailDeletes) {
      const mailLengths = await get(mailLengthsRef);
      const { orderMails, orderMailsUnRead, supportMails, supportMailsUnRead } = mailLengths.val();

      if (mail.order) {
        const mailRef = ref(database, `inbox/orderMails/${mail.id}`);
        await update(mailLengthsRef, { orderMails: orderMails - 1 });
        await remove(mailRef);
      }

      if (mail.support) {
        const mailRef = ref(database, `inbox/supportMails/${mail.id}`);
        await update(mailLengthsRef, { supportMails: supportMails - 1 });
        await remove(mailRef);
      }

      if (mail.unRead && mail.order) {
        await update(mailLengthsRef, { orderMailsUnRead: orderMailsUnRead - 1 });
      }

      if (mail.unRead && mail.support) {
        await update(mailLengthsRef, { supportMailsUnRead: supportMailsUnRead - 1 });
      }
    }

    setMailDeletes([]);
  }

  return (
    <div className={styles.deleteButton} onClick={() => deleteMail()}>
      <FontAwesomeIcon icon={faTrash} />
    </div>
  );
}
