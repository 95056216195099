import { ref, get } from "firebase/database";
import { database } from "../settings/settings";

export default async function fetchProduct(id) {
  const productRefDB = ref(database, `store/products/${id}`);

  const productsDB = await get(productRefDB);

  if (!productsDB.val()) return {};

  return productsDB.val();
}
