import styles from "./LoginForm.module.css";
import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../settings/settings";
import { useContext } from "react";
import { TopBarContext } from "../../../context/TopBarContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../Tooltip/Tooltip";
import ButtonLoader from "../Loaders/ButtonLoader";

export default function LoginForm() {
  const { showForm, setShowForm } = useContext(TopBarContext);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  async function login(event) {
    event.preventDefault();
    setLoading(true);

    const emailValue = event.target.email.value.trim();
    const passwordValue = event.target.password.value.trim();
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

    try {
      if (passwordValue.length <= 0 || emailValue.length <= 0) {
        return setMessage(
          <Tooltip arrow="top" messageType="warning">
            Please fill all fields
          </Tooltip>
        );
      }

      if (!regex.test(emailValue)) {
        return setMessage(
          <Tooltip arrow="top" messageType="warning">
            Not valid email
          </Tooltip>
        );
      }

      const userCredential = await signInWithEmailAndPassword(auth, emailValue, passwordValue);
      setShowForm(false);
    } catch (error) {
      console.log(error);
      setMessage(
        <Tooltip arrow="top" messageType="error">
          {error.code.replace("auth/", "")}
        </Tooltip>
      );
    } finally {
      setLoading(false);
    }
  }

  function clearMessage() {
    setMessage("");
  }

  return (
    <form className={`${styles.form} ${showForm ? styles.active : ""}`} onSubmit={(event) => login(event)}>
      <div className={styles.inputContainer}>
        <input placeholder="Email" type="text" name="email" autoComplete="true" onKeyDown={() => clearMessage()} />
        <input placeholder="Passord" type="password" name="password" autoComplete="true" onKeyDown={() => clearMessage()} />
        <button className={styles.loginButton} type="submit">
          {loading ? <ButtonLoader /> : "Login"}
        </button>
      </div>
      {message ? (
        <div className={styles.messageContainer}>
          {message}
          <div className={styles.clearMessageButton} onClick={() => clearMessage()}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
      ) : (
        ""
      )}
    </form>
  );
}
