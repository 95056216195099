import styles from "./Buttons.module.css";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
export default function Buttons() {
  const { cart, setCart, setShowCart } = useContext(AppContext);

  function clearCart(event) {
    event.stopPropagation();
    localStorage.removeItem("cart");
    setCart([]);
  }

  function closeCart() {
    setShowCart(false);
  }

  return (
    <>
      {cart.length > 0 ? (
        <div className={styles.buttonContainer}>
          <Link to="/bestilling" onClick={() => closeCart()}>
            <div className={styles.orderButton}>Bestill</div>
          </Link>
          <div className={styles.clearCartButton} onClick={(event) => clearCart(event)} id="adasd">
            Tøm handlekurv
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
