import styles from "./About.module.css";
import { useState, useEffect } from "react";
import { ref, get } from "firebase/database";
import { database } from "../../settings/settings";
import AboutForm from "./AboutForm";
import Content from "./Content";
export default function About() {
  const [toggleForm, setToggleForm] = useState(false);
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchContent() {
      const contentRef = ref(database, `about/content`);

      try {
        const content = await get(contentRef);
        setContent(content.val());
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    fetchContent();
  }, []);

  return <main className={styles.main}>{toggleForm ? <AboutForm setToggleForm={setToggleForm} content={content} /> : <>{!loading ? <Content setToggleForm={setToggleForm} content={content} /> : <div className={styles.loadingImage}></div>}</>}</main>;
}
