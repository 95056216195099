import { database, storage } from "../../settings/settings";
import { ref as database_ref, update } from "firebase/database";
import { ref as storage_ref, uploadBytes, getDownloadURL } from "firebase/storage";
import validation from "./validation";
import DisplayMessage from "../common/DisplayMessage/DisplayMessage";

export default async function upload({ event, userDetails, setUserDetails, setLoading, setEmailError, setPhoneError, setRoleError, setMessage }) {
  event.preventDefault();
  const profileImage = event.target.profileImage.files[0];
  const roleValue = event.target.role.value.trim();
  const phoneValue = event.target.phone.value.trim();
  const emailvalue = event.target.email.value.trim().toLowerCase();

  const userRefDB = database_ref(database, `users/${userDetails.id}`);
  const userRefStorage = storage_ref(storage, `users/${userDetails.id}/profileImage`);

  const validated = validation({ userDetails, profileImage, roleValue, phoneValue, emailvalue, setEmailError, setPhoneError, setRoleError, setMessage });

  if (!validated) return;

  try {
    setLoading(true);

    let newUserDetails = userDetails;
    let imageUrl = userDetails.profileImage;

    if (profileImage) {
      await uploadBytes(userRefStorage, profileImage);
      const downloadURL = await getDownloadURL(userRefStorage);
      imageUrl = downloadURL;
    }

    const updates = { role: roleValue, phone: phoneValue, email: emailvalue, profileImage: imageUrl };

    await update(userRefDB, updates);

    newUserDetails = { ...newUserDetails, ...updates };
    setUserDetails(newUserDetails);
    window.location.reload();
    setLoading(false);
  } catch (error) {
    console.log(error);
    setMessage(<DisplayMessage messageType="error">Oops... Noe gikk galt</DisplayMessage>);
  } finally {
  }
}
