// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAfTiBnLJjLF9C7Acah3kCyD0qfFSE-3O4",
  authDomain: "myrholt-johannessen.firebaseapp.com",
  databaseURL: "https://myrholt-johannessen-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "myrholt-johannessen",
  storageBucket: "myrholt-johannessen.appspot.com",
  messagingSenderId: "337936080858",
  appId: "1:337936080858:web:90d88e42c356d85721aae3",
  measurementId: "G-FKCT427TGV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const database = getDatabase(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
