import { ref as database_ref, push, update } from "firebase/database";
import { ref as storage_ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { database, storage } from "../../../settings/settings";
import validation from "./validation";
import upLoadSubFiles from "./upLoadSubFiles";
import fetchSubFileUrls from "../../../actions/fetchSubFileUrls";
import DisplayMessage from "../DisplayMessage/DisplayMessage";

export default async function submit(event, args) {
  const { setLoading, setSubFileDeletes, setSubFiles, setMessage, setPreviewUrl, product, formType } = args;
  event.preventDefault();
  setLoading(true);
  setMessage("");
  const productImg = event.target.productImgInput.files[0];
  const titlevalue = event.target.title.value.trim();
  const descriptionValue = event.target.description.value.trim();
  const priceValue = parseFloat(event.target.price.value);
  const stockValue = Math.round(parseFloat(event.target.stock.value));
  const discountValue = parseFloat(event.target.discount.value);
  const featuredValue = event.target.checkbox.checked;

  const validated = validation(event, args);

  if (!validated) {
    setLoading(false);
    return;
  }

  try {
    const productsRefDB = database_ref(database, `store/products`);
    const productKey = formType === "edit" ? product.id : push(productsRefDB).key;
    const productRefDB = database_ref(database, `store/products/${productKey}`);
    const productsRefStorage = storage_ref(storage, `store/products/${productKey}/productImage:${productKey}`);

    if (!productKey) return setMessage(<DisplayMessage messageType="error">Oops... noe gikk galt</DisplayMessage>);

    if (productImg) {
      await uploadBytes(productsRefStorage, productImg);
    }

    const url = productImg ? await getDownloadURL(productsRefStorage) : product.url;
    await update(productRefDB, { id: productKey, title: titlevalue, description: descriptionValue, price: priceValue, stock: stockValue, discount: discountValue, featured: featuredValue, url: url });
    await upLoadSubFiles(productKey, args);

    const newSubFiles = await fetchSubFileUrls(product.id);

    setSubFiles(newSubFiles);
    if (formType === "add") {
      setSubFiles([]);
      event.target.reset();
      setPreviewUrl("");
    }
    setMessage(<DisplayMessage messageType="success">Produkt lagt til</DisplayMessage>);
    setSubFileDeletes([]);
  } catch (error) {
    console.log(error);
    setMessage(<DisplayMessage messageType="error">Oops... noe gikk galt</DisplayMessage>);
  } finally {
    setLoading(false);
  }
}
