import styles from "./Cards.module.css";
import { useContext } from "react";
import { ShopContext } from "../../context/ShopContext";
import ProductCard from "../common/Cards/ProductCard/ProductCard";

export default function Cards() {
  const { products, setProducts } = useContext(ShopContext);

  return (
    <div className={styles.cards}>
      {products.map((product) => {
        return (
          <div key={product.id}>
            <ProductCard products={products} setProducts={setProducts} product={product} />
          </div>
        );
      })}
    </div>
  );
}
