import styles from "./Phone.module.css";
import { useState, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faPenToSquare, faXmark } from "@fortawesome/free-solid-svg-icons";
import { ProfileContext } from "../../context/ProfileContext";
export default function Phone() {
  const phoneInputRef = useRef();
  const { userDetails, phoneError, setPhoneError } = useContext(ProfileContext);
  const [toggleField, setToggleField] = useState(false);

  function cancle() {
    phoneInputRef.current.value = userDetails.phone;
    setToggleField(false);
    setPhoneError(false);
  }
  return (
    <div className={styles.phoneContainer}>
      <div className={styles.phoneIcon}>
        <FontAwesomeIcon icon={faPhone} />
      </div>
      <div className={styles.phone} id={!toggleField ? styles.phoneActive : ""}>
        <p className={styles.phoneText}>+47 {userDetails.phone}</p>
        <div className={styles.editButton} onClick={() => setToggleField(true)}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </div>
      </div>
      <div className={styles.fieldContainer} id={toggleField ? styles.inputFieldActive : ""}>
        <label htmlFor="phone">Skrive in nytt nummer</label>
        {phoneError ? <p className={styles.error}>Fyll in ditt number</p> : ""}
        <div className={styles.inputContainer}>
          <input placeholder="Nummer" name="phone" id="phone" defaultValue={userDetails.phone} ref={phoneInputRef} />
          <div className={styles.cancleButton} onClick={() => cancle()}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
      </div>
    </div>
  );
}
