import DisplayMessage from "../common/DisplayMessage/DisplayMessage";

export default function validation(event, args) {
  const nameValue = event.target.name.value.trim();
  const emailValue = event.target.email.value.trim();
  const phoneValue = event.target.phone.value.trim();
  const addressValue = event.target.address.value.trim();
  const postalCodeValue = event.target.postalCode.value.trim();
  const postOfficeValue = event.target.postOffice.value.trim();

  const { setNameError, setEmailError, setPhoneError, setAdressError, setPostalCodeError, setPostOfficeError, setMessage } = args;

  if (nameValue <= 0) {
    setNameError(true);
  } else {
    setNameError(false);
  }

  if (emailValue <= 0) {
    setEmailError(true);
  } else {
    setEmailError(false);
  }

  if (phoneValue <= 0) {
    setPhoneError(true);
  } else {
    setPhoneError(false);
  }

  if (addressValue <= 0) {
    setAdressError(true);
  } else {
    setAdressError(false);
  }

  if (postalCodeValue <= 0) {
    setPostalCodeError(true);
  } else {
    setPostalCodeError(false);
  }

  if (postOfficeValue <= 0) {
    setPostOfficeError(true);
  } else {
    setPostOfficeError(false);
  }

  if (nameValue <= 0 || emailValue <= 0 || phoneValue <= 0 || addressValue <= 0 || postalCodeValue <= 0 || postOfficeValue <= 0) {
    setMessage(<DisplayMessage messageType="warning">Vennligst fyll ut alle felt</DisplayMessage>);
    return false;
  }
  return true;
}
