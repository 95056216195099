import styles from "./ContactForm.module.css";
import { useState } from "react";
import { database } from "../../settings/settings";
import { ref, push, update, get } from "firebase/database";
import DisplayMessage from "../common/DisplayMessage/DisplayMessage";

export default function ContactForm() {
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [message, setMessage] = useState("");

  async function send(event) {
    event.preventDefault();

    const supportMailsRef = ref(database, "inbox/supportMails");
    const mailLengthsRef = ref(database, "inbox/mailLengths");

    const nameValue = event.target.name.value;
    const emailValue = event.target.email.value;
    const messageValue = event.target.message.value;
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

    if (nameValue.length <= 0) {
      setNameError(true);
    } else {
      setNameError(false);
    }
    const emailTest = regex.test(emailValue);
    if (!emailTest) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    if (messageValue.length <= 0) {
      setMessageError(true);
    } else {
      setMessageError(false);
    }

    if (nameValue.length <= 0 || emailValue.length <= 0 || !emailTest || messageValue.length <= 0) {
      setMessage(<DisplayMessage messageType="warning">Vennligst fyll ut alle felt</DisplayMessage>);
      return;
    }

    try {
      const mailLengths = await get(mailLengthsRef);
      const { supportMails, supportMailsUnRead } = mailLengths.val();

      await push(supportMailsRef, { name: nameValue, email: emailValue, message: messageValue, unRead: true, support: true, order: false, date: Date.now() });
      await update(mailLengthsRef, { supportMails: supportMails + 1, supportMailsUnRead: supportMailsUnRead + 1 });
      setMessage(<DisplayMessage messageType="success">Melding sendt</DisplayMessage>);
    } catch (error) {
      console.log(error);
      setMessage(<DisplayMessage messageType="error">Oops... Noe gikk galt</DisplayMessage>);
    }

    event.target.reset();
  }

  function clearMessage() {
    setMessage("");
  }

  return (
    <form className={styles.form} type="submit" onSubmit={send}>
      {message ? <div className={styles.messageContainer}>{message}</div> : <h2>Send oss en melding</h2>}

      <label htmlFor="name">Ditt navn</label>
      {nameError ? <p className={styles.error}>Skriv ditt navn</p> : ""}
      <input placeholder="Navn" name="name" onKeyDown={() => clearMessage()} />

      <label htmlFor="email">Din email</label>
      {emailError ? <p className={styles.error}>Ugyldig email</p> : ""}
      <input placeholder="Email" name="email" onKeyDown={() => clearMessage()} />

      <label htmlFor="text">Skriv en melding</label>
      {messageError ? <p className={styles.error}>Vennligst skriv en melding</p> : ""}
      <textarea placeholder="Melding" name="message" onKeyDown={() => clearMessage()} />
      <button>Send</button>
    </form>
  );
}
