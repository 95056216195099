import styles from "./WarningIcon.module.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../../Tooltip/Tooltip";

export default function WarningIcon(props) {
  const [toggleToolTip, setToggleToolTip] = useState(false);
  return (
    <>
      <div className={styles.warningContainer} id={props.item.amount > props.item.stock ? styles.active : ""} onMouseOver={() => setToggleToolTip(true)} onMouseLeave={() => setToggleToolTip(false)}>
        <div className={styles.warningIcon}>
          <FontAwesomeIcon icon={faCircleExclamation} />
        </div>
        <div className={styles.toolTopContainer} id={toggleToolTip ? styles.active : ""}>
          <Tooltip messageType="warning">Lengre levering</Tooltip>
        </div>
      </div>
    </>
  );
}
