import styles from "./CartCard.module.css";
import calculateDiscount from "../../helpers/calculateDiscount";
import DecreaseButton from "./DecreaseButton";
import IncreaseButton from "./IncreaseButton";
import RemoveButton from "./RemoveButton";
import WarningIcon from "./WarningIcon";

export default function CartCard(props) {
  const { item, cardType } = props;

  return (
    <div className={`${styles.card} ${cardType === "small" ? styles.cardSmall : ""}`}>
      <div className={styles.imageContainer}>
        <img src={item.url} alt="produkt image" />
      </div>
      <div className={`${styles.cardBody} ${cardType === "small" ? styles.cardBodySmall : ""}`}>
        <div>
          <h2>{item.title.slice(0, 16)}</h2>
        </div>
        <div className={`${styles.amountContainer} ${cardType === "small" ? styles.amountContainerSmall : ""}`}>
          <DecreaseButton item={item} />
          <p className={item.amount > item.stock ? styles.amountRed : ""}>{item.amount}</p>
          <IncreaseButton item={item} />
          <WarningIcon item={item} />
        </div>
        <div>
          <div className={styles.priceContainer}>
            <p>{!item.discount ? item.price : calculateDiscount(item.price, item.discount)} NOK</p>
            {item.discount ? <p className={styles.discountPrice}>{item.price} NOK</p> : ""}
          </div>
          <RemoveButton id={item.id} />
        </div>
      </div>
    </div>
  );
}
