import { ref, update, get } from "firebase/database";
import { database } from "../../settings/settings";

export default async function markAsRead(index, mails, type) {
  const mailLengthsRef = ref(database, "inbox/mailLengths");

  let typeofMail = "";
  if (type === "order") {
    typeofMail = "orderMails";
  }

  if (type === "support") {
    typeofMail = "supportMails";
  }

  const emailRef = ref(database, `inbox/${typeofMail}/${mails[index].id}`);

  try {
    const mailLengths = await get(mailLengthsRef);
    const { supportMailsUnRead } = mailLengths.val();

    if (mails[index].unRead) {
      await update(emailRef, { unRead: false });
      await update(mailLengthsRef, { supportMailsUnRead: supportMailsUnRead - 1 });
    }
  } catch (error) {
    console.log(error);
  }
}
