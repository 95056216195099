import styles from "./Role.module.css";
import { useState, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faXmark } from "@fortawesome/free-solid-svg-icons";
import { ProfileContext } from "../../context/ProfileContext";

export default function Role() {
  const roleInputRef = useRef();
  const { userDetails, roleError, setRoleError } = useContext(ProfileContext);
  const [toggleField, setToggleField] = useState(false);

  function cancle() {
    roleInputRef.current.value = userDetails.role;
    setToggleField(false);
    setRoleError(false);
  }

  return (
    <>
      <div className={styles.role} id={!toggleField ? styles.roleActive : ""}>
        <p className={styles.roleText}>{userDetails.role}</p>
        <div className={styles.editButton} onClick={() => setToggleField(true)}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </div>
      </div>

      <div className={styles.fieldContainer} id={toggleField ? styles.inputFieldActive : ""}>
        <label htmlFor="role">Skrive in ny rolle</label>
        {roleError ? <p className={styles.error}>Fyll ur ny rolle</p> : ""}
        <div className={styles.inputContainer}>
          <input placeholder="Rolle" name="role" id="role" defaultValue={userDetails.role} ref={roleInputRef} />
          <div className={styles.cancleButton} onClick={() => cancle()}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
      </div>
    </>
  );
}
