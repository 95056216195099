import styles from "./ProductCard.module.css";
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../context/AppContext";
import calculateDiscount from "../../helpers/calculateDiscount";
import stockText from "../../helpers/stockText";
import FavoriteButton from "./FavoriteButton";
import EditButton from "./EditButton";
import DeleteButton from "./DeleteButton";
import AddToCartButton from "./AddToCartButton";
import RemoveFavoriteButton from "./RemoveFavoriteButton";

export default function ProductCard(props) {
  const { product, products, setProducts, cardType } = props;

  const { user } = useContext(AppContext);
  const [imageLoading, setImageLoading] = useState(true);

  const filterdTitle = product.title.replaceAll(" ", "-").replaceAll("/", "-");

  return (
    <div className={styles.card}>
      <div className={styles.actionButton}>
        {cardType !== "favorite" ? <FavoriteButton product={product} /> : <RemoveFavoriteButton product={product} />}
        {user ? <EditButton product={product} /> : ""}
        {user ? <DeleteButton product={product} products={products} setProducts={setProducts} /> : ""}
      </div>
      <Link to={`/detaljer/${product.id}/${filterdTitle}`}>
        <div className={styles.imgContainer}>
          <img src={product.url} alt="" onLoad={() => setImageLoading(false)} onError={() => setImageLoading(false)} />
          {imageLoading ? <div className={styles.imageLoader}></div> : ""}
          <p className={styles.stockStatus} id={product.stock <= 0 ? styles.outOfStock : ""}>
            {stockText(product.stock)}
          </p>
        </div>
        <h2>{product.title}</h2>
        <div className={styles.priceContainer}>
          <p>{!product.discount ? product.price : calculateDiscount(product.price, product.discount)} NOK</p>
          {product.discount ? <p className={styles.discountPrice}>{product.price} NOK</p> : ""}
        </div>
      </Link>
      <AddToCartButton product={product} />
    </div>
  );
}
