import styles from "./OrderForm.module.css";
import { useState, useContext } from "react";
import React from "react";
import submit from "./submit";
import DisplayMessage from "../common/DisplayMessage/DisplayMessage";
import { OrderContext } from "../../context/OrderContext";

export default function OrderForm() {
  const { products, setProducts } = useContext(OrderContext);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [adressError, setAdressError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [postOfficeError, setPostOfficeError] = useState(false);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  function click() {
    setActive(!active);
  }

  return (
    <form className={styles.form} onSubmit={(event) => submit(event, { setNameError, setEmailError, setPhoneError, setAdressError, setPostalCodeError, setPostOfficeError, setMessage })}>
      <h2>Kontakt info</h2>
      {message ? <div className={styles.message}>{message}</div> : ""}
      <div className={styles.grid}>
        <div>
          <label htmlFor="name">Navn</label>
          {nameError ? <p className={styles.error}>Skriv ditt navn</p> : ""}
          <input name="name" id="name" placeholder="Navn" type="text" />
        </div>
        <div>
          <label htmlFor="email">Email</label>
          {emailError ? <p className={styles.error}>Skriv din email</p> : ""}
          <input name="email" id="email" placeholder="Email" type="text" />
        </div>
        <div>
          <label htmlFor="phone">Mobil</label>
          {phoneError ? <p className={styles.error}>Skriv ditt telefon nr</p> : ""}
          <input name="phone" id="phone" placeholder="Telefon" type="number" />
        </div>
        <div>
          <label htmlFor="address">Adresse</label>
          {adressError ? <p className={styles.error}>Skriv din adresse</p> : ""}
          <input name="address" id="address" placeholder="Adresse" type="text" />
        </div>
        <div>
          <label htmlFor="postalCode">Postnummer</label>
          {postalCodeError ? <p className={styles.error}>Skriv ditt postnummer</p> : ""}
          <input name="postalCode" id="postalCode" placeholder="Postnummer" type="number" />
        </div>
        <div>
          <label htmlFor="postOffice">Poststed</label>
          {postOfficeError ? <p className={styles.error}>skriv ditt poststed</p> : ""}
          <input name="postOffice" id="postOffice" placeholder="Poststed" type="text" />
        </div>
      </div>
      <label>Kommentar</label>
      <textarea placeholder="Kommentar" />
      <button type="submit">Bestill</button>
    </form>
  );
}
