import styles from "./ProductImg.module.css";
import { useContext } from "react";
import { ProductFormContext } from "../../../context/ProductFormContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function ProductImg() {
  const { product, previewUrl, setPreviewUrl } = useContext(ProductFormContext);

  function handleChange(event) {
    const file = event.target.files[0];
    if (!file) return;
    const url = URL.createObjectURL(file);
    setPreviewUrl(url);
  }
  return (
    <>
      <label htmlFor="productImgInput">
        <div className={styles.imgContainer}>{previewUrl || product ? <img src={!previewUrl ? product.url : previewUrl} /> : <FontAwesomeIcon icon={faPlus} />}</div>
        <input className={styles.fileInput} type="file" name="productImgInput" id="productImgInput" accept="image/*" onChange={(event) => handleChange(event)} />
      </label>
    </>
  );
}
