import styles from "./Materials.module.css";
import { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";

export default function Materials() {
  const [materials, setMaterials] = useState([]);

  const newMaterialButtonRef = useRef();

  function addMaterial(event) {
    const file = event.target.files[0];
    if (!file) return;

    const url = URL.createObjectURL(file);
    let newMaterials = materials;

    setMaterials([...newMaterials, { file: file, previewUrl: url }]);
    newMaterialButtonRef.current.value = "";
  }

  function replaceMaterial(event, index) {
    const file = event.target.files[0];

    if (!file) return;

    const url = URL.createObjectURL(file);
    let newMaterials = materials;

    newMaterials[index].previewUrl = url;

    setMaterials([...newMaterials]);
  }

  function removeMaterial(index) {
    const newMaterials = materials.filter((material, MatIndex) => MatIndex !== index);

    setMaterials([...newMaterials]);
  }

  return (
    <>
      <label className={styles.materialsInputLabel}>Materialer</label>
      <div className={styles.materialsContainer}>
        {materials.map((material, index) => {
          return (
            <div key={index} className={styles.material}>
              <input placeholder="materiale" id={`materialInput${index}`} name={`materialInput${index}`}></input>
              <label htmlFor={`materialImage${index}`} className={styles.materialImage}>
                <img src={material.previewUrl} />
              </label>
              <input className={styles.fileInput} type="file" id={`materialImage${index}`} name={`materialImage${index}`} onChange={(event) => replaceMaterial(event, index)} />
              <div className={styles.removeMaterialButton} onClick={() => removeMaterial(index)}>
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
          );
        })}
        <div className={styles.newMaterialButtonWrapper}>
          <label className={styles.newMaterialButton} htmlFor="newMaterialButton">
            <FontAwesomeIcon icon={faPlus} />
          </label>
          <input className={styles.fileInput} type="file" id="newMaterialButton" name="newMaterialButton" onChange={(event) => addMaterial(event)} ref={newMaterialButtonRef} />
        </div>
      </div>
    </>
  );
}
