import styles from "./Favorites.module.css";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import Cards from "./Cards";

export default function Favorites() {
  const { favorites } = useContext(AppContext);

  return (
    <>
      <main className={styles.main}>
        <h1 className={styles.h1}>Favoriter</h1>
        {favorites.length <= 0 ? <p className={styles.noFavoritesMessage}>Ingen favoriter å vise</p> : ""}
        <Cards />
      </main>
    </>
  );
}
