import styles from "./RemoveButton.module.css";
import { useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default function RemoveButton(props) {
  const { cart, setCart } = useContext(AppContext);

  function remove(event, id) {
    event.stopPropagation();
    const newCart = cart.filter((item) => item.id !== id);

    localStorage.setItem("cart", JSON.stringify(newCart));
    setCart(newCart);
  }

  return (
    <div className={styles.removeButton} onClick={(event) => remove(event, props.id)}>
      <FontAwesomeIcon icon={faTrash} />
    </div>
  );
}
