export default function dateFormatter(timestamp) {
  const now = new Date();
  const date = new Date(timestamp);

  const formattedHours = String(date.getHours()).padStart(2, "0");
  const formattedMinutes = String(date.getMinutes()).padStart(2, "0");

  if (date.toDateString() === now.toDateString()) {
    return `${formattedHours}:${formattedMinutes}`;
  } else {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}`;
    return formattedDate;
  }
}
//now.getFullYear()
