import styles from "./RemoveFavoriteButton.module.css";
import { useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import removeFromFavorites from "../../../../actions/removeFromFavorites";
export default function RemoveFavoriteButton(props) {
  const { favorites, setFavorites } = useContext(AppContext);

  return (
    <div className={styles.icon} onClick={() => removeFromFavorites(props.product, favorites, setFavorites)}>
      <FontAwesomeIcon icon={faXmark} />
    </div>
  );
}
