import styles from "./FeaturedProducts.module.css";
import React, { useState, useEffect } from "react";
import { ref, get, query, orderByChild, equalTo } from "firebase/database";
import { database } from "../../settings/settings";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProductCard from "../common/Cards/ProductCard/ProductCard";

export default function FeaturedProducts() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function fecthProducts() {
      const productsRef = query(ref(database, `store/products`), orderByChild("featured"), equalTo(true));

      try {
        const productsDB = await get(productsRef);
        if (!productsDB.val()) return;

        const keys = Object.keys(productsDB.val());

        let newProducts = [];
        for (const key of keys) {
          const product = productsDB.val()[key];
          newProducts = [...newProducts, product];
        }

        setProducts(newProducts);
      } catch (error) {
        console.log(error);
      }
    }
    fecthProducts();
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 660 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 660, min: 460 },
      items: 2,
    },
    mobile2: {
      breakpoint: { max: 460, min: 0 },
      items: 1,
    },
  };

  return (
    <div className={styles.featuredContainer}>
      <h2 className={styles.h2}>Noen av våre produkter</h2>
      <Carousel dotListClass={styles.dots} className={styles.carousel} responsive={responsive} infinite={true} showDots={true}>
        {products.map((product) => {
          return (
            <div key={product.id} className={styles.cardWrapper}>
              <ProductCard products={products} setProducts={setProducts} product={product} />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
