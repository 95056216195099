import styles from "./Navigation.module.css";
import { NavLink } from "react-router-dom";
export default function Navigation() {
  return (
    <nav className={styles.nav}>
      <li>
        <NavLink to="/galleri">GALLERI</NavLink>
      </li>
      <li>
        <NavLink to="/shop">SHOP</NavLink>
      </li>
      <li>
        <NavLink to="/kontakt-oss">KONTAKT OSS</NavLink>
      </li>
      <li>
        <NavLink to="/om-oss">OM OSS</NavLink>
      </li>
    </nav>
  );
}
