import styles from "./ProductDetails.module.css";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ProductDetailsContext } from "../../context/ProductDetailsContext";
import fetchProduct from "../../actions/fetchProduct";
import fetchSubFileUrls from "../../actions/fetchSubFileUrls";
import Gallery from "./Gallery";
import ProductInfo from "./ProductInfo";

export default function ProductDetails() {
  const [product, setProduct] = useState({});
  const [subFiles, setSubFiles] = useState([]);
  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const product = await fetchProduct(id);
        setProduct(product);

        const subFileUrls = await fetchSubFileUrls(id);
        setSubFiles(subFileUrls);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  function goBack() {
    navigate(-1);
  }

  return (
    <ProductDetailsContext.Provider value={{ product, subFiles }}>
      <main className={styles.main}>
        <div className={styles.goBackButtonWrapper}>
          <div className={styles.goBackButton} onClick={() => goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} />
            <p>Tilbake</p>
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <Gallery />
          <ProductInfo />
        </div>
      </main>
    </ProductDetailsContext.Provider>
  );
}
