import styles from "./CartButton.module.css";
import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/AppContext";
import { TopBarContext } from "../../../context/TopBarContext";
export default function CartButton() {
  const { cart, showCart, setShowCart, cartButtonRef, user } = useContext(AppContext);
  const { showForm } = useContext(TopBarContext);

  function openCart() {
    setShowCart(!showCart);
  }

  return (
    <div className={`${styles.cartButton} ${!showForm || user ? styles.active : ""}`} onClick={() => openCart()} ref={cartButtonRef}>
      <FontAwesomeIcon icon={faCartShopping} />
      {cart.length <= 0 ? "" : <p>{cart.length}</p>}
    </div>
  );
}
