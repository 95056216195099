import styles from "./FavoriteButton.module.css";
import { useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as borderFaHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import addToFavorite from "../../../../actions/addToFavorites";
import removeFromFavorites from "../../../../actions/removeFromFavorites";

export default function FavoriteButton(props) {
  const { product } = props;
  const { favorites, setFavorites } = useContext(AppContext);

  return (
    <>
      {!favorites.find((fav) => fav.id === props.product.id) ? (
        <div className={styles.icon} onClick={() => addToFavorite(product, favorites, setFavorites)}>
          <FontAwesomeIcon icon={borderFaHeart} />
        </div>
      ) : (
        <div className={styles.icon} onClick={() => removeFromFavorites(product, favorites, setFavorites)}>
          <FontAwesomeIcon icon={faHeart} />
        </div>
      )}
    </>
  );
}
