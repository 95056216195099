import { ref, get } from "firebase/database";
import { database } from "../settings/settings";

export default async function fetchGallerys() {
  const productsRef = ref(database, `gallerys/gallery`);

  const gallerysDB = await get(productsRef);

  if (!gallerysDB.val()) return;

  const keys = Object.keys(gallerysDB.val());

  let gallerys = [];
  for (const key of keys) {
    const product = gallerysDB.val()[key];
    gallerys = [...gallerys, product];
  }
  return gallerys;
}
