import "./css/Global.css";
import styles from "./App.module.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { useState, useEffect, useRef } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./settings/settings";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { AppContext } from "./context/AppContext";
import logo from "./images/logo.png";
import Home from "./components/Home/Home";
import Shop from "./components/Shop/Shop";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import Contact from "./components/Contact/Contact";
import Gallery from "./components/Gallery/Gallery ";
import Order from "./components/Order/Order";
import Favorites from "./components/Favorites/Favorites";
import Inbox from "./components/Inbox/Inbox";
import Profile from "./components/Profile/Profile";
import AddProduct from "./components/AddProduct/AddProduct";
import EditProduct from "./components/EditProduct/EditProduct";
import AddGallery from "./components/AddGallery/AddGallery";
import EditGallery from "./components/EditGallery/EditGallery";
import About from "./components/About/About";
import TopBar from "./components/common/TopBar/TopBar";
import Navigation from "./components/common/Navigation/Navigation";
import Cart from "./components/common/Cart/Cart";

function App() {
  const [favorites, setFavorites] = useState(JSON.parse(localStorage.getItem("favorites")) ? JSON.parse(localStorage.getItem("favorites")) : []);
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart")) ? JSON.parse(localStorage.getItem("cart")) : []);
  const [showCart, setShowCart] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [user, setUser] = useState(null);
  const cartButtonRef = useRef(null);

  useEffect(function () {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      }
    });
  }, []);

  return (
    <AppContext.Provider value={{ cart, setCart, showCart, setShowCart, totalPrice, setTotalPrice, favorites, setFavorites, cartButtonRef, user }}>
      <div className="App">
        <Router>
          <header className={styles.header}>
            <div className={styles.logoContainer}>
              <Link to="/">
                <img src={logo} />
              </Link>
            </div>
            <TopBar />
          </header>
          <Cart />
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route path="/shop" element={<Shop />}></Route>
            <Route path="/detaljer/:id/:title" element={<ProductDetails />}></Route>
            <Route path="/om-oss" element={<About />}></Route>
            <Route path="/galleri" element={<Gallery />}></Route>
            <Route path="/bestilling" element={<Order />}></Route>
            <Route path="/favoriter" element={<Favorites />}></Route>
            <Route path="/kontakt-oss" element={<Contact />}></Route>
            {user ? <Route path="/nytt-produkt" element={<AddProduct />}></Route> : ""}
            {user ? <Route path="/nytt-galleri" element={<AddGallery />}></Route> : ""}
            {user ? <Route path="/rediger-galleri/:id" element={<EditGallery />}></Route> : ""}
            {user ? <Route path="/rediger-produkt/:id/:title" element={<EditProduct />}></Route> : ""}
            {user ? <Route path="/inbox" element={<Inbox />}></Route> : ""}
            {user ? <Route path="/profil" element={<Profile />}></Route> : ""}
          </Routes>
          <footer className={styles.navigationContainer}>
            <Navigation />
          </footer>
        </Router>
      </div>
    </AppContext.Provider>
  );
}
export default App;
