import { ref as database_ref, remove, get } from "firebase/database";
import { ref as storage_ref, listAll, deleteObject } from "firebase/storage";
import { database, storage } from "../settings/settings";

export default async function deleteProduct(product) {
  const productRefDB = database_ref(database, `store/products/${product.id}`);
  const productRefStorage = storage_ref(storage, `store/products/${product.id}`);
  const subFilesRefDB = database_ref(database, `store/subFiles/${product.id}`);

  const subFilesDB = await get(subFilesRefDB);

  const subFilesIDS = Object.keys(subFilesDB.val());

  for (const subFileID of subFilesIDS) {
    const subFileRefStorage = storage_ref(storage, `store/subFiles/${product.id}/${subFileID}`);
    const subFile = await listAll(subFileRefStorage);
    await deleteObject(subFile.items[0]);
  }

  await remove(subFilesRefDB);

  const productFiles = await listAll(productRefStorage);
  await deleteObject(productFiles.items[0]);
  await remove(productRefDB);
}
