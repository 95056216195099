import styles from "./CloseFormButton.module.css";
import { useContext } from "react";
import { TopBarContext } from "../../../context/TopBarContext";
export default function CloseFormButton() {
  const { showForm, setShowForm } = useContext(TopBarContext);

  function closeForm() {
    setShowForm(false);
  }
  return (
    <div className={`${styles.closeFormButton} ${showForm ? styles.active : ""}`} onClick={() => closeForm()}>
      Lukk
    </div>
  );
}
