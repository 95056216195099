import styles from "./Contact.module.css";
import { useState, useEffect } from "react";
import { ref, get } from "firebase/database";
import { database } from "../../settings/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ContactForm from "./ContactForm";

export default function Contact() {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const userRef = ref(database, `users/`);

    async function fetchUsers() {
      try {
        const usersVal = await get(userRef);

        const keys = Object.keys(usersVal.val());
        let newUsers = [];

        keys.forEach(function (key) {
          newUsers = [...newUsers, { id: key, ...usersVal.val()[key] }];
        });
        setUsers(newUsers);
      } catch (error) {
        console.log(error);
      }
    }
    fetchUsers();
  }, []);

  return (
    <main className={styles.main}>
      <div className={styles.contactInfoBackground}>
        <h1 className={styles.h1}>Kontakt oss</h1>
        <div className={styles.contactInfoContainer}>
          {users.map((user) => {
            return (
              <div key={user.id}>
                <div className={styles.imgContainer}>
                  <img src={user.profileImage} alt="" />
                </div>
                <p className={styles.name}>
                  {user.name} {user.lastName}
                </p>
                <p className={styles.role}>{user.role}</p>

                <div className={styles.phoneAndEmailContainer}>
                  <div className={styles.phoneContainer}>
                    <div className={styles.phoneIcon}>
                      <FontAwesomeIcon icon={faPhone} />
                    </div>
                    <p className={styles.number}>+47 {user.phone}</p>
                  </div>

                  <div className={styles.emailContainer}>
                    <div className={styles.emailIcon}>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                    <p className={styles.email}>{user.email}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ContactForm />
    </main>
  );
}
