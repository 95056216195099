export default function addToCart(event, product, cart, setCart, setShowCart) {
  event.stopPropagation();
  let newCart = cart;
  const foundInCart = newCart.find((item) => item.id === product.id);

  if (foundInCart) {
    return false;
  }

  localStorage.setItem("cart", JSON.stringify([...newCart, { ...product, amount: 1 }]));
  setCart([...newCart, { ...product, amount: 1 }]);

  if (window.innerWidth > 500) {
    setShowCart(true);
  }
  return true;
}
