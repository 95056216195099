import styles from "./SubImgs.module.css";
import { useContext } from "react";
import { ProductFormContext } from "../../../context/ProductFormContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import NewSubImgsButton from "./NewSubImgsButton";
export default function SubImgs() {
  const { subFiles, setSubFiles, subFileDeletes, setSubFileDeletes } = useContext(ProductFormContext);

  function handleChange(event, index) {
    const file = event.target.files[0];
    if (!file) return;
    const url = URL.createObjectURL(file);

    let newSubFiles = subFiles;

    newSubFiles[index].url = url;
    setSubFiles([...newSubFiles]);
  }

  function removeSubFile(id) {
    const newSubFiles = subFiles.filter((subFile) => subFile.id !== id);
    setSubFiles(newSubFiles);

    let newSubFileDeletes = subFileDeletes;
    const foundSubFile = newSubFileDeletes.find((subFile) => subFile.id === id);

    if (!foundSubFile) {
      newSubFileDeletes = [...newSubFileDeletes, { id: id }];
      setSubFileDeletes(newSubFileDeletes);
    }
  }

  return (
    <div className={styles.subImgsContainer}>
      {subFiles.map((subFile, index) => {
        return (
          <div key={subFile.id} className={styles.subImgContainer}>
            <label htmlFor={`subImg${subFile.id}`}>
              <div className={styles.subImage}>
                <img src={subFile.url} />
              </div>
              <input type="file" name={`subImg${subFile.id}`} id={`subImg${subFile.id}`} accept="image/*" onChange={(event) => handleChange(event, index)} />
            </label>
            <div className={styles.removeButton} onClick={() => removeSubFile(subFile.id)}>
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div>
        );
      })}
      <NewSubImgsButton />
    </div>
  );
}
