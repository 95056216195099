import styles from "./ProductForm.module.css";
import { useEffect, useState } from "react";
import { ProductFormContext } from "../../../context/ProductFormContext";
import { useParams } from "react-router-dom";
import fetchProduct from "../../../actions/fetchProduct";
import fetchSubFileUrls from "../../../actions/fetchSubFileUrls";
import submit from "./submit";
import ButtonLoader from "../Loaders/ButtonLoader";
import ProductImg from "./ProductImg";
import SubImgs from "./SubImgs";
import Fields from "./Fields";

export default function ProductForm(props) {
  const { formType } = props;
  const [product, setProduct] = useState("");
  const [subFiles, setSubFiles] = useState([]);
  const [subFileDeletes, setSubFileDeletes] = useState([]);
  const [previewUrl, setPreviewUrl] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [stockError, setStockError] = useState(false);
  const [discountError, setDiscountError] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    if (props.formType === "edit") {
      async function fetch() {
        const product = await fetchProduct(id);
        setProduct(product);

        const subFileUrls = await fetchSubFileUrls(id);
        setSubFiles(subFileUrls);
      }
      fetch();
    }
  }, []);

  return (
    <ProductFormContext.Provider value={{ formType: props.formType, product, subFiles, setSubFiles, subFileDeletes, setSubFileDeletes, previewUrl, setPreviewUrl, titleError, descriptionError, priceError, stockError, discountError }}>
      <form className={styles.form} onSubmit={(event) => submit(event, { subFiles, setSubFiles, subFileDeletes, setSubFileDeletes, setLoading, setTitleError, setDescriptionError, setPriceError, setStockError, setDiscountError, setMessage, setPreviewUrl, product, formType })}>
        <ProductImg />
        <SubImgs />
        {message ? <div className={styles.messageContainer}>{message}</div> : ""}
        <Fields />
        <button type="submit">{!loading ? "Legg til produkt" : <ButtonLoader />}</button>
      </form>
    </ProductFormContext.Provider>
  );
}
