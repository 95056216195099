import styles from "./TotalPrice.module.css";
import { useEffect, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import calculateDiscount from "../helpers/calculateDiscount";
export default function TotalPrice() {
  const { cart, totalPrice, setTotalPrice } = useContext(AppContext);

  useEffect(() => {
    let newTotalPrice = 0;
    cart.forEach((item) => {
      newTotalPrice = newTotalPrice + calculateDiscount(item.price, item.discount) * item.amount;
    });
    const roundedPrice = parseFloat(newTotalPrice).toFixed(2);
    setTotalPrice(roundedPrice);
  }, [cart]);

  return (
    <div className={styles.totalPriceContainer}>
      <p>Totalt:</p>
      <p>{totalPrice} NOK</p>
    </div>
  );
}
