import styles from "./Cart.module.css";
import { useEffect, useContext, useRef } from "react";
import { AppContext } from "../../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faXmark } from "@fortawesome/free-solid-svg-icons";
import Cards from "./Cards";
import TotalPrice from "./TotalPrice";
import Buttons from "./Buttons";

export default function Cart() {
  const { cart, showCart, setShowCart, cartButtonRef } = useContext(AppContext);
  const cartRef = useRef(null);

  useEffect(() => {
    function clickOutside(event) {
      if (cartButtonRef.current && cartButtonRef.current.contains(event.target)) {
        return;
      }

      if (!cartRef.current.contains(event.target)) {
        setShowCart(false);
      }
    }

    document.addEventListener("click", clickOutside);

    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);

  function closeCart() {
    setShowCart(false);
  }

  return (
    <div className={`${styles.cart} ${showCart ? styles.active : ""}`} ref={cartRef}>
      <div className={styles.topBar}>
        <div className={styles.iconAndHeader}>
          <div className={styles.cartIcon}>
            <FontAwesomeIcon icon={faCartShopping} />
          </div>
          <h2 className={styles.h2}>Cart</h2>
        </div>
        <div className={styles.closeCartButton} onClick={() => closeCart()}>
          <FontAwesomeIcon icon={faXmark} />
        </div>
      </div>
      <Cards />
      {cart.length <= 0 ? <div className={styles.emptyCartText}>Handlekurven er tom</div> : ""}
      <TotalPrice />
      <Buttons />
    </div>
  );
}
