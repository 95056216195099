import { ref as database_ref, push, remove, update, get } from "firebase/database";
import { ref as storage_ref, deleteObject, listAll, uploadBytes, getDownloadURL } from "firebase/storage";
import { database, storage } from "../../../settings/settings";

export default async function upLoadSubFiles(productKey, args) {
  const { subFiles, subFileDeletes } = args;

  for (const subImagesDelete of subFileDeletes) {
    const subFileRefDB = database_ref(database, `store/subFiles/${productKey}/${subImagesDelete.id}`);
    const subFileRefStorage = storage_ref(storage, `store/subFiles/${productKey}/${subImagesDelete.id}`);

    const snapshot = await get(subFileRefDB);
    const exists = snapshot.exists();

    if (exists) {
      const subFilesList = await listAll(subFileRefStorage);
      await deleteObject(subFilesList.items[0]);
      await remove(subFileRefDB);
    }
  }

  for (const subFile of subFiles) {
    if (subFile.file) {
      const subFilesRefDB = database_ref(database, `store/subFiles/${productKey}`);
      const subFileKey = push(subFilesRefDB).key;
      const subFileRefDB = database_ref(database, `store/subFiles/${productKey}/${subFileKey}`);
      const subFileRefStorage = storage_ref(storage, `store/subFiles/${productKey}/${subFileKey}/${subFile.file.name}`);
      await uploadBytes(subFileRefStorage, subFile.file);
      const subFileUrl = await getDownloadURL(subFileRefStorage);
      await update(subFileRefDB, { url: subFileUrl });
    }
  }
}
