import styles from "./Modal.module.css";
import { useContext } from "react";
import { GalleryContext } from "../../context/GalleryContext";

export default function Modal() {
  const { modalUrl, showModal, setShowmodal } = useContext(GalleryContext);

  return (
    <div
      className={`${styles.imageModal} ${showModal ? "" : styles.hide}`}
      onClick={() => {
        setShowmodal(false);
      }}
    >
      <div className={styles.imageContainer}>
        <img
          src={modalUrl}
          alt=""
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </div>
    </div>
  );
}
