export default function checkScrollPosition(setTopBarFixed) {
  function handleScroll() {
    if (window.scrollY >= 155) {
      setTopBarFixed(true);
    } else {
      setTopBarFixed(false);
    }
  }
  handleScroll();
  window.addEventListener("scroll", handleScroll);

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}
