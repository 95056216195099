import styles from "./NewSubImgsButton.module.css";
import { useContext, useRef } from "react";
import { ProductFormContext } from "../../../context/ProductFormContext";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
export default function NewSubImgsButton() {
  const { subFiles, setSubFiles } = useContext(ProductFormContext);
  const newSubFileButton = useRef();

  function handleChange(event) {
    const file = event.target.files[0];
    if (!file) return;
    const url = URL.createObjectURL(file);

    let newSubFiles = subFiles;

    setSubFiles([...newSubFiles, { id: uuidv4(), url: url, file: file }]);
    newSubFileButton.current.value = "";
  }

  return (
    <label htmlFor="newSubImageButton" className={styles.newSubImageButton}>
      <FontAwesomeIcon icon={faPlus} />
      <input type="file" name="newSubImageButton" id="newSubImageButton" accept="image/*" onChange={(event) => handleChange(event, subFiles.length, "add")} ref={newSubFileButton} />
    </label>
  );
}
