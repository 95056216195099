import styles from "./Orders.module.css";
import { useEffect, useContext } from "react";
import { ref, onValue } from "firebase/database";
import { database } from "../../settings/settings";
import { InboxContext } from "../../context/InboxContext";
import dateFormatter from "../common/formatters/dateFormatter";
import CheckBox from "./CheckBox";
import markAsRead from "./markAsRead";

export default function Support() {
  const { supportMails, setSupportMails } = useContext(InboxContext);

  const ordersMailRef = ref(database, "inbox/ordersMails");

  useEffect(() => {
    onValue(ordersMailRef, (snapshot) => {
      if (snapshot.val()) {
        const keys = Object.keys(snapshot.val());

        let mails = [];
        keys.forEach(function (key) {
          mails = [...mails, { id: key, ...snapshot.val()[key] }];
        });
        setSupportMails(mails);
      } else {
        setSupportMails([]);
      }
    });
  }, []);

  return (
    <div className={styles.orders}>
      {supportMails.length <= 0 ? <div className={styles.message}>Ingen ordre å vise</div> : ""}
      {supportMails.map((mail, index) => {
        return mail.order ? (
          <div key={mail.id} className={styles.card} id={mail.unRead ? styles.unRead : ""} onClick={() => markAsRead(index, supportMails, "order")}>
            <div className={styles.nameContainer}>
              <CheckBox mail={mail} />
              <p className={styles.name}>{mail.name}</p>
            </div>
            <p className={styles.text}>{mail.message}</p>
            <p className={styles.date}>{dateFormatter(mail.date)}</p>
          </div>
        ) : (
          ""
        );
      })}
    </div>
  );
}
