import styles from "./Cards.module.css";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import convertFileSize from "../common/helpers/convertFileSize";
import CartCard from "../common/Cards/CartCard/CartCard";

export default function Cards() {
  const { cart, setCart } = useContext(AppContext);

  function handleFileChange(event, index) {
    const file = event.target.files[0];
    if (!file) return;
    const url = URL.createObjectURL(file);
    let newCart = cart;

    newCart[index] = { ...newCart[index], customeFile: { file, preViewUrl: url } };
    setCart([...newCart]);
  }

  function removeAttachment(index) {
    let newCart = cart;
    newCart[index].customeFile = null;
    setCart([...newCart]);
  }

  return (
    <div className={styles.cards}>
      {cart.map((item, index) => {
        return (
          <div key={item.id}>
            <div className={styles.cardWrapper}>
              <CartCard item={item} cardType="" />
              {item.customeFile ? (
                <div>
                  <div className={styles.attachmentCard}>
                    <div className={styles.attachmentImg}>
                      <img src={item.customeFile.preViewUrl} />
                    </div>
                    <div className={styles.cardBody}>
                      <p>{item.customeFile.file.name.slice(0, 12)}</p>
                      <p></p>
                      <p className={styles.fileSize}>{convertFileSize(item.customeFile.file.size)}</p>
                    </div>
                    <div className={styles.removeAttachmentButton} onClick={() => removeAttachment(index)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <label htmlFor={`fileAttachment${item.id}`} className={styles.attachmentButton}>
                    <p>Last opp ditt design her</p>
                  </label>
                  <input name={`fileAttachment${item.id}`} id={`fileAttachment${item.id}`} type="file" onChange={(event) => handleFileChange(event, index)} />
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
