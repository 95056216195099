import styles from "./Fields.module.css";
import { useContext } from "react";
import { ProductFormContext } from "../../../context/ProductFormContext";
export default function Fields() {
  const { formType, product, titleError, descriptionError, priceError, stockError, discountError } = useContext(ProductFormContext);
  return (
    <>
      <label className={styles.label} htmlFor="title">
        Tittel
      </label>
      {titleError ? <p className={styles.error}>skriv en tittel</p> : ""}
      <input className={styles.input} placeholder="Tittel" name="title" id="title" type="text" defaultValue={product.title} />

      <label className={styles.label} htmlFor="description">
        Beskrivelse
      </label>
      {descriptionError ? <p className={styles.error}>Skriv en beskrivelse</p> : ""}
      <textarea className={styles.textarea} placeholder="Beskrivelse" name="description" id="description" type="text" defaultValue={product.description} />

      <label className={styles.label} htmlFor="price">
        pris
      </label>
      {priceError ? <p className={styles.error}>Skriv en pris</p> : ""}
      <input className={styles.input} placeholder="Pris" name="price" id="price" type="number" defaultValue={product.price} />

      <div className={styles.gridContainer}>
        <div>
          <label className={styles.label} htmlFor="stock">
            Lager status
          </label>
          {stockError ? <p className={styles.error}>Skriv lager status</p> : ""}
          <input className={styles.input} placeholder="Lager status" name="stock" id="stock" type="number" defaultValue={product.stock} />
        </div>
        <div>
          <label className={styles.label} htmlFor="discount">
            Rabatt %
          </label>
          {discountError ? <p className={styles.error}>Skriv in rabatt</p> : ""}
          <input className={styles.input} placeholder="Rabatt" name="discount" id="discount" type="number" defaultValue={formType === "add" ? 0 : product.discount} />
        </div>
      </div>

      <div className={styles.checkBoxContainer}>
        <label htmlFor="featuredCheckbox">Featured</label>
        <input type="checkbox" name="checkbox" id="featuredCheckbox" defaultChecked={product.featured} />
      </div>
    </>
  );
}
