import DisplayMessage from "../common/DisplayMessage/DisplayMessage";

export default function validaton(event, args) {
  const bannerImage = event.target.bannerImage.files[0];
  const h1Value = event.target.h1.value.trim();
  const descriptionValue = event.target.description.value.trim();

  const { setMessage, setH1Error, setDescriptionError } = args;

  if (h1Value.length <= 0) {
    setH1Error(true);
  } else {
    setH1Error(false);
  }

  if (descriptionValue.length <= 0) {
    setDescriptionError(true);
  } else {
    setDescriptionError(false);
  }

  if (h1Value.length <= 0 || descriptionValue.length <= 0) {
    setMessage(<DisplayMessage messageType="warning">Vennligst fyll ut all felt</DisplayMessage>);
    return false;
  }

  return true;
}
