import styles from "./Shop.module.css";
import { useState, useEffect } from "react";
import { ShopContext } from "../../context/ShopContext";
import fecthProducts from "../../actions/fecthProducts";
import DummyCards from "../common/DummyCards/DummyCards";
import Cards from "./Cards";

export default function Shop() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const productsDB = await fecthProducts();
        setProducts(productsDB);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <ShopContext.Provider value={{ products, setProducts }}>
      <main className={styles.main}>
        <h1 className={styles.h1}>Shop</h1>
        {products.length <= 0 && !loading ? <div className={styles.noCardsMessage}>Ingen produkter å vise</div> : ""}
        {!loading ? <Cards /> : <DummyCards />}
      </main>
    </ShopContext.Provider>
  );
}
