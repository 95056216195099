import { ref, get } from "firebase/database";
import { database } from "../settings/settings";

export default async function fecthProducts() {
  const productsRef = ref(database, `store/products`);

  const productsDB = await get(productsRef);

  if (!productsDB.val()) return;

  const keys = Object.keys(productsDB.val());

  let products = [];
  for (const key of keys) {
    const product = productsDB.val()[key];
    products = [...products, product];
  }

  return products;
}
