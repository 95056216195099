import styles from "./Tooltip.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

export default function Tooltip(props) {
  let messageType = "";

  if (props.messageType === "normal") {
    messageType = styles.normal;
  } else if (props.messageType === "error") {
    messageType = styles.error;
  } else if (props.messageType === "warning") {
    messageType = styles.warning;
  } else if (props.messageType === "success") {
    messageType = styles.success;
  } else {
    messageType = "";
  }

  return (
    <div className={`${styles.tooltip} ${messageType}`}>
      {props.children}
      <FontAwesomeIcon className={props.arrow === "top" ? styles.arrowTop : styles.arrowBottom} icon={faCaretDown} />
    </div>
  );
}
