import styles from "./Profile.module.css";
import { useState, useContext, useEffect } from "react";
import { ref, get } from "firebase/database";
import { database } from "../../settings/settings";
import { AppContext } from "../../context/AppContext";
import { ProfileContext } from "../../context/ProfileContext";
import upload from "./upload";
import ProfileImage from "./ProfileImage";
import Role from "./Role";
import Phone from "./Phone";
import Email from "./Email";
import ButtonLoader from "../common/Loaders/ButtonLoader";
import DisplayMessage from "../common/DisplayMessage/DisplayMessage";

export default function Profile() {
  const { user } = useContext(AppContext);
  const [userDetails, setUserDetails] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchUserDetails() {
      try {
        const usersRef = ref(database, `users/${user.auth.currentUser.uid}`);
        const userDetials = await get(usersRef);

        setUserDetails(userDetials.val());
      } catch (error) {
        console.log(error);
        setMessage(<DisplayMessage messageType="error">Oops... Noe gikk galt</DisplayMessage>);
      }
    }

    fetchUserDetails();
  }, []);

  return (
    <ProfileContext.Provider value={{ userDetails, loading, emailError, setEmailError, phoneError, setPhoneError, roleError, setRoleError }}>
      <main className={styles.main}>
        <form className={styles.Profile} onSubmit={(event) => upload({ event, userDetails, setUserDetails, setLoading, setEmailError, setPhoneError, setRoleError, setMessage })}>
          <ProfileImage />
          {message ? <div className={styles.message}>{message}</div> : ""}
          <div className={styles.nameAndRoleContainer}>
            <p className={styles.name}>{`${userDetails.name} ${userDetails.lastName}`}</p>
            <Role />
          </div>

          <div className={styles.contactInformation}>
            <Phone />
            <Email />
          </div>
          <div className={styles.buttonContainer}>
            <button type="submit">{!loading ? "Oppdater profil" : <ButtonLoader />}</button>
          </div>
        </form>
      </main>
    </ProfileContext.Provider>
  );
}
