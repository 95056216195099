import styles from "./Home.module.css";
import { useState, useEffect, useContext } from "react";
import { ref, get } from "firebase/database";
import { database } from "../../settings/settings";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import EditHomeForm from "./EditHomeForm";
import FeaturedProducts from "./FeaturedProducts";
export default function Home() {
  const { user } = useContext(AppContext);
  const [toggleForm, setToggleForm] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [h1Value, setH1Value] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [content, setContent] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const homeContentRefDB = ref(database, `home/content`);

        const contentDB = await get(homeContentRefDB);

        setContent(contentDB.val());
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <main className={styles.main}>
      <div className={styles.bannerContainer} style={{ backgroundImage: `url(${!previewUrl ? content.bannerUrl : previewUrl})` }}>
        <div className={`${styles.banner}`}>
          <div className={styles.h1Container}>
            <h1>{h1Value ? h1Value : content.h1}</h1>
            {user && !toggleForm ? (
              <div className={styles.editButton} onClick={() => setToggleForm(!toggleForm)}>
                <FontAwesomeIcon icon={faPenToSquare} />
              </div>
            ) : (
              ""
            )}
          </div>
          <p>{descriptionValue ? descriptionValue : content.description}</p>
          <div className={styles.buttonContainer}>
            <Link to="/shop">
              <div className={styles.shopButton}>Se våre produkter</div>
            </Link>
          </div>
        </div>
        {user && toggleForm ? <EditHomeForm content={content} setH1Value={setH1Value} setDescriptionValue={setDescriptionValue} setToggleForm={setToggleForm} previewUrl={previewUrl} setPreviewUrl={setPreviewUrl} /> : ""}
      </div>
      <FeaturedProducts />;
    </main>
  );
}
