import styles from "./Gallery.module.css";
import { useState, useContext } from "react";
import { ProductDetailsContext } from "../../context/ProductDetailsContext";

export default function Gallery() {
  const { product, subFiles } = useContext(ProductDetailsContext);
  const [mainImageUrl, setMainImageUrl] = useState("");

  function handleMainImage(event) {
    setMainImageUrl(event.target.src);
  }

  return (
    <div className={styles.galleryContainer}>
      <div className={styles.mainImageContainer}>
        <img src={mainImageUrl ? mainImageUrl : product.url} alt="product image" />
      </div>
      <div className={`${styles.subImagesContainer} ${subFiles.length > 0 ? styles.active : ""}`}>
        <div className={styles.subImage}>
          <img src={product.url} alt="product image" onMouseOver={(event) => handleMainImage(event)} />
        </div>
        {subFiles.map((subImage, index) => {
          return (
            <div key={index} className={styles.subImage}>
              <img src={subImage.url} alt="product image" onMouseOver={(event) => handleMainImage(event)} onClick={(event) => handleMainImage(event)} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
