import styles from "./NavMenuButton.module.css";
import { useContext } from "react";
import { TopBarContext } from "../../../context/TopBarContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default function NavMenuButton() {
  const { showNavigation, setShowNavigation, navigationButtonRef } = useContext(TopBarContext);
  function Navigation() {
    setShowNavigation(!showNavigation);
  }

  return (
    <div className={styles.menuButton} onClick={() => Navigation()} ref={navigationButtonRef}>
      <FontAwesomeIcon icon={faBars} />
    </div>
  );
}
