import validation from "./validation";
import DisplayMessage from "../common/DisplayMessage/DisplayMessage";

export default async function submit(event, args) {
  event.preventDefault();

  const nameValue = event.target.name.value.trim();
  const emailValue = event.target.email.value.trim();
  const phoneValue = event.target.phone.value.trim();
  const addressValue = event.target.address.value.trim();
  const postalCodeValue = event.target.postalCode.value.trim();
  const postOfficeValue = event.target.postOffice.value.trim();

  const { setMessage } = args;

  const validated = validation(event, args);

  if (!validated) return;

  try {
    setMessage(<DisplayMessage messageType="success">Takk for din bestilling! Vi tar kontakt snarest</DisplayMessage>);
  } catch (error) {
    console.log(error);
  }
}
