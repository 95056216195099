import styles from "./FilterButton.module.css";
import { useState, useContext } from "react";
import { InboxContext } from "../../context/InboxContext";

export default function FilterButton() {
  const { supportMails, setSupportMails } = useContext(InboxContext);

  function handleSelect(event) {
    const selectValue = event.target.value;

    let newSupportMails = [...supportMails];

    if (selectValue === "unRead") {
      const sortedArray = newSupportMails.sort((a, b) => {
        if (a.unRead && !b.unRead) {
          return -1;
        } else if (!a.unRead && b.unRead) {
          return 1;
        } else {
          return 0;
        }
      });
      setSupportMails(sortedArray);
    }

    if (selectValue === "date") {
      newSupportMails.sort((a, b) => new Date(b.date) - new Date(a.date));
      newSupportMails.reverse();
      setSupportMails(newSupportMails);
    }
  }

  return (
    <select className={styles.filterButton} onChange={(event) => handleSelect(event)}>
      <option value="date">Dato</option>
      <option value="unRead">Uleste</option>
    </select>
  );
}
