import styles from "./OrderButton.module.css";
import { useContext } from "react";
import { InboxContext } from "../../context/InboxContext";
export default function OrderButton() {
  const { setMailDeletes, toggleOrders, setToggleOrders, setToggleSupport, orderMailsUnRead } = useContext(InboxContext);
  function showOrders() {
    setToggleOrders(true);
    setToggleSupport(false);
    setMailDeletes([]);
  }
  return (
    <div className={`${styles.orderButton} ${toggleOrders ? styles.active : ""}`} onClick={() => showOrders()}>
      Bestillinger
      {!orderMailsUnRead <= 0 ? <p className={styles.orderEmailAmount}>{orderMailsUnRead}</p> : ""}
    </div>
  );
}
