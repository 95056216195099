import styles from "./TopBar.module.css";
import { useState, useEffect, useRef } from "react";
import checkScrollPosition from "./checkScrollPosition";
import clickOutsideNavigation from "./clickOutsideNavigation";
import clickOutsideUserButton from "./clickOutsideUserButton";
import { TopBarContext } from "../../../context/TopBarContext";
import ActionButtons from "./ActionButtons";
import Navigation from "../../common/Navigation/Navigation";

export default function TopBar() {
  const [topBarFixed, setTopBarFixed] = useState(false);
  const [showNavigation, setShowNavigation] = useState(false);
  const [showForm, setShowForm] = useState(window.innerWidth < 500 ? false : true);
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigationRef = useRef(null);
  const navigationButtonRef = useRef(null);
  const profileButtonRef = useRef(null);

  useEffect(() => {
    checkScrollPosition(setTopBarFixed);
    clickOutsideNavigation(navigationRef, navigationButtonRef, setShowNavigation);
    clickOutsideUserButton(profileButtonRef, setToggleMenu);
  }, []);
  function closeNavigation() {
    setShowNavigation(false);
  }

  return (
    <TopBarContext.Provider value={{ showForm, setShowForm, showNavigation, setShowNavigation, toggleMenu, setToggleMenu, navigationButtonRef, profileButtonRef }}>
      <div className={styles.topBarContainer}>
        <div className={`${styles.topbBar} ${topBarFixed ? styles.positionFixed : ""}`}>
          <div className={styles.actionBar}>
            <ActionButtons />
          </div>
          <div className={styles.navigationWrapper}>
            <div className={`${styles.navigationContainer} ${showNavigation ? styles.active : ""}`}>
              <div className={`${styles.navigation} ${showNavigation ? styles.active : ""}`} onClick={() => closeNavigation()} ref={navigationRef}>
                <Navigation />
              </div>
            </div>
          </div>
        </div>
      </div>
    </TopBarContext.Provider>
  );
}
