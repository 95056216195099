import styles from "./Inbox.module.css";
import { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { database } from "../../settings/settings";
import { InboxContext } from "../../context/InboxContext";
import Orders from "./Orders";
import Support from "./Support";
import OrderButton from "./OrderButton";
import SupportButton from "./SupportButton";
import FilterButton from "./FilterButton";
import DeleteButton from "./DeleteButton";

export default function Inbox() {
  const [toggleOrders, setToggleOrders] = useState(true);
  const [toggleSupport, setToggleSupport] = useState(false);
  const [mailDeletes, setMailDeletes] = useState([]);
  const [totalMailsUnRead, setTotalMailsUnRead] = useState(0);
  const [supportMailsUnRead, setSupportMailsUnRead] = useState(0);
  const [orderMailsUnRead, setOrderMailsUnRead] = useState(0);
  const [supportMails, setSupportMails] = useState([]);

  const mailLengthsRef = ref(database, "inbox/mailLengths");

  useEffect(() => {
    onValue(mailLengthsRef, (snapshot) => {
      if (snapshot.val()) {
        const { orderMailsUnRead, supportMailsUnRead } = snapshot.val();
        setOrderMailsUnRead(orderMailsUnRead);
        setSupportMailsUnRead(supportMailsUnRead);
        setTotalMailsUnRead(orderMailsUnRead + supportMailsUnRead);
      }
    });
  }, []);

  return (
    <InboxContext.Provider value={{ mailDeletes, setMailDeletes, toggleOrders, setToggleOrders, toggleSupport, setToggleSupport, orderMailsUnRead, supportMailsUnRead, supportMails, setSupportMails }}>
      <main className={styles.main}>
        <div className={styles.inbox}>
          <div className={styles.topBar}>
            <h1>Inbox</h1>
            <div className={styles.actionButtons}>
              <OrderButton />
              <SupportButton />
            </div>
          </div>
          <div className={styles.unreadMessagesAmount}>
            <p>{totalMailsUnRead} Uleste meldinger</p>
            <div className={styles.filterAndDeleteContainer}>
              <FilterButton />
              <DeleteButton />
            </div>
          </div>
          <div className={styles.inboxBody}>
            {toggleOrders ? <Orders /> : ""}
            {toggleSupport ? <Support /> : ""}
          </div>
        </div>
      </main>
    </InboxContext.Provider>
  );
}
