import styles from "./Description.module.css";

export default function Description() {
  return (
    <div className={styles.descriptionContainer}>
      <p className={styles.description}>Takk for at du bestiller fra Myrholt & Johannessen !</p>
      <p className={styles.description}>
        Standard fraktpriser finner du her, og er basert på posten sine priser : <a href="https://www.posten.no/sende/i-norge/norgespakke">Se priser her</a>
      </p>
      <p className={styles.description}>Vi tar den pakken som matcher din bestilling.</p>
      <p className={styles.description}>Vi kommer tilbake med fraktpris hvis du har bestilt flere varer og pakken blir ugunstig i størrelse.</p>
      <p className={styles.description}>Frakt vil da avtales via mail eller per telefon.</p>
      <p className={styles.description}>Vi ser frem mot å levere vårt design til deg!</p>
    </div>
  );
}
