import styles from "./IncreaseButton.module.css";
import { useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function IncreaseButton(props) {
  const { cart, setCart } = useContext(AppContext);

  function increase(id) {
    let newCart = cart;

    const foundItem = newCart.find((item) => item.id === id);
    foundItem.amount = foundItem.amount + 1;

    setCart([...newCart]);
  }
  return (
    <div className={styles.increaseButton} onClick={() => increase(props.item.id)}>
      <FontAwesomeIcon icon={faPlus} />
    </div>
  );
}
