import styles from "./AddGallery.module.css";
import GalleryForm from "../common/GalleryForm/GalleryForm";

export default function AddGallery() {
  return (
    <main className={styles.main}>
      <GalleryForm formType="add" />
    </main>
  );
}
