import styles from "./ProductInfo.module.css";
import { useContext } from "react";
import { ProductDetailsContext } from "../../context/ProductDetailsContext";
import calculateDiscount from "../common/helpers/calculateDiscount";
import stockText from "../common/helpers/stockText";
import AddToFavoritesButton from "./AddToFavoritesButton";
import AddtoCartButton from "./AddtoCartButton";

export default function ProductInfo() {
  const { product } = useContext(ProductDetailsContext);

  return (
    <div className={styles.productInfoContainer}>
      <h1>{product.title}</h1>
      <div className={styles.PriceAndStockContainer}>
        <div className={styles.priceContainer}>
          <p>{!product.discount ? product.price : calculateDiscount(product.price, product.discount)} NOK</p>
          {product.discount ? <p className={styles.discountPrice}>{product.price} NOK</p> : ""}
        </div>
        <div>
          <p className={styles.stockStatus} id={product.stock <= 0 ? styles.outOfStock : ""}>
            {stockText(product.stock)}
          </p>
        </div>
      </div>
      <pre className={styles.description}>{product.description}</pre>
      <h2>Har du et eget design?</h2>
      <p className={styles.designText}>Du kan laste opp ditt eget design når du har lagt varen i handlekurven.</p>
      <p className={styles.designText2}>Vi vil kontakte deg og avtale hvordan layout skal se ut.</p>
      <AddToFavoritesButton />
      <AddtoCartButton />
    </div>
  );
}
