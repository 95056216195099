export default function stockText(stock) {
  let text = "";

  if (stock <= 0) {
    text = "Ikke på lager";
  } else if (stock > 50) {
    text = "+50 På lager";
  } else {
    text = `${stock} På lager`;
  }

  return text;
}
