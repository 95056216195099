import styles from "./Support.module.css";
import { useEffect, useContext } from "react";
import { ref, onValue, query, orderByChild, equalTo, limitToFirst } from "firebase/database";
import { database } from "../../settings/settings";
import { InboxContext } from "../../context/InboxContext";
import dateFormatter from "../common/formatters/dateFormatter";
import CheckBox from "./CheckBox";
import markAsRead from "./markAsRead";

export default function Support() {
  const { supportMails, setSupportMails } = useContext(InboxContext);
  //orderByChild("unRead"), equalTo(true)
  const supportMailsRef = query(ref(database, "inbox/supportMails"), orderByChild("timestamp"), limitToFirst(20));

  useEffect(() => {
    onValue(supportMailsRef, (snapshot) => {
      if (snapshot.val()) {
        const keys = Object.keys(snapshot.val());

        let mails = [];
        keys.forEach(function (key) {
          mails = [...mails, { id: key, ...snapshot.val()[key] }];
        });
        setSupportMails(mails);
      } else {
        setSupportMails([]);
      }
    });
  }, []);

  return (
    <div className={styles.support}>
      {supportMails.length <= 0 ? <div className={styles.message}>Ingen support mails å vise</div> : ""}
      {supportMails.map((mail, index) => {
        return mail.support ? (
          <div key={mail.id} className={styles.card} id={mail.unRead ? styles.unRead : ""} onClick={() => markAsRead(index, supportMails, "support")}>
            <div className={styles.nameContainer}>
              <CheckBox mail={mail} />
              <p className={styles.name}>{mail.name}</p>
            </div>
            <p className={styles.text}>{mail.message.length > 10 ? mail.message.slice(0, 20) : mail.message}</p>
            <p className={styles.date}>{dateFormatter(mail.date)}</p>
          </div>
        ) : (
          ""
        );
      })}
    </div>
  );
}
