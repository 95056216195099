import styles from "./Content.module.css";
import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../context/AppContext";

export default function Content(props) {
  const { user } = useContext(AppContext);
  return (
    <>
      <div className={styles.aboutImageContainer}>
        <img src={props.content.url} alt="about image" />
        {user ? (
          <div className={styles.editButton} onClick={() => props.setToggleForm(true)}>
            <FontAwesomeIcon icon={faPenToSquare} />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={styles.textContainer}>
        <pre>{props.content.text}</pre>
      </div>
    </>
  );
}
