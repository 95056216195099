import { ref as database_ref, push, update } from "firebase/database";
import { ref as storage_ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { database, storage } from "../../../settings/settings";
import DisplayMessage from "../DisplayMessage/DisplayMessage";

export default async function submit(event, args) {
  event.preventDefault();

  const { formType, setMessage, gallery, setPreviewUrl, setLoading } = args;
  setLoading(true);
  const galleryImg = event.target.galleryImg.files[0];

  if (!galleryImg) {
    setMessage(<DisplayMessage messageType="warning">Vennligst legg til et bilde</DisplayMessage>);
    return;
  }

  try {
    const gallerysRefDB = database_ref(database, `gallerys/gallery`);
    const galleryKey = formType === "edit" ? gallery.id : push(gallerysRefDB).key;

    const galleryRefDB = database_ref(database, `gallerys/gallery/${galleryKey}`);
    const galleryStorageRef = storage_ref(storage, `gallerys/gallery/${galleryKey}/galleryImage:${galleryKey}`);

    if (!galleryKey) return setMessage(<DisplayMessage messageType="error">Oops... noe gikk galt</DisplayMessage>);

    if (formType === "edit") {
      let url = "";
      if (galleryImg) {
        await uploadBytes(galleryStorageRef, galleryImg);
        url = await getDownloadURL(galleryStorageRef);
      }

      if (!galleryImg) {
        url = gallery.url;
      }

      await update(galleryRefDB, { id: galleryKey, url: url });
      setMessage(<DisplayMessage messageType="success">Galleri oppdatert</DisplayMessage>);
    }

    if (formType === "add") {
      await uploadBytes(galleryStorageRef, galleryImg);
      const url = await getDownloadURL(galleryStorageRef);
      await update(galleryRefDB, { id: galleryKey, url: url });
      setMessage(<DisplayMessage messageType="success">Galleri lagt til</DisplayMessage>);
      event.target.reset();
      setPreviewUrl("");
    }
  } catch (error) {
    console.log(error);
    setMessage(<DisplayMessage messageType="error">Oops... noe gikk galt</DisplayMessage>);
  } finally {
    setLoading(false);
  }
}
