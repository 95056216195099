import { ref, get } from "firebase/database";
import { database } from "../settings/settings";

export default async function fetchSubImages(id) {
  const subFileRefDB = ref(database, `store/subFiles/${id}`);

  const subFilesDB = await get(subFileRefDB);

  if (!subFilesDB.val()) return [];

  const keys = Object.keys(subFilesDB.val());

  let subFiles = [];
  for (const key of keys) {
    const subFileDB = subFilesDB.val()[key];
    subFiles.push({ id: key, url: subFileDB.url });
  }

  return subFiles;
}
