import styles from "./DeleteButton.module.css";
import { useContext } from "react";
import { ref as database_ref, remove } from "firebase/database";
import { ref as storage_ref, listAll, deleteObject } from "firebase/storage";
import { database, storage } from "../../settings/settings";
import { GalleryContext } from "../../context/GalleryContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default function DeleteButton(props) {
  const { gallerys, setGallerys } = useContext(GalleryContext);

  async function deleteProduct(event) {
    event.stopPropagation();

    const galleryRefDB = database_ref(database, `gallerys/gallery/${props.gallery.id}`);
    const galleryRefStorage = storage_ref(storage, `gallerys/gallery/${props.gallery.id}`);

    if (window.confirm("Vil du slette dette produktet?")) {
      try {
        const fileList = await listAll(galleryRefStorage);
        await remove(galleryRefDB);

        for (const file of fileList.items) {
          await deleteObject(file);
        }

        const newGallerys = gallerys.filter((gallery) => gallery.id !== props.gallery.id);
        setGallerys(newGallerys);
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div className={styles.deleteButton} onClick={(event) => deleteProduct(event)}>
      <FontAwesomeIcon icon={faTrash} />
    </div>
  );
}
