import styles from "./Email.module.css";
import { useState, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPenToSquare, faXmark } from "@fortawesome/free-solid-svg-icons";
import { ProfileContext } from "../../context/ProfileContext";

export default function Email() {
  const emailInputRef = useRef();
  const { userDetails, emailError, setEmailError } = useContext(ProfileContext);
  const [showField, setShowField] = useState(false);

  function cancle() {
    emailInputRef.current.value = userDetails.email;
    setShowField(false);
    setEmailError(false);
  }

  return (
    <div className={styles.emailContainer}>
      <div className={styles.emailIcon}>
        <FontAwesomeIcon icon={faEnvelope} />
      </div>

      <div className={styles.email} id={!showField ? styles.emailActive : ""}>
        <p className={styles.emailText}>{userDetails.email}</p>
        <div className={styles.editButton} onClick={() => setShowField(true)}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </div>
      </div>

      <div className={styles.fieldContainer} id={showField ? styles.inputFieldActive : ""}>
        <label htmlFor="email">Skrive in ny email</label>
        {emailError ? <p className={styles.error}>Ugyldig email</p> : ""}
        <div className={styles.inputContainer}>
          <input placeholder="Email" name="email" id="email" defaultValue={userDetails.email} ref={emailInputRef} />
          <div className={styles.cancleButton} onClick={() => cancle()}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
      </div>
    </div>
  );
}
