export default function validation({ userDetails, profileImage, roleValue, phoneValue, emailvalue, setEmailError, setPhoneError, setRoleError, setMessage }) {
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

  if (roleValue === userDetails.role && phoneValue === userDetails.phone && emailvalue === userDetails.email && !profileImage) {
    return;
  }

  if (roleValue.length <= 0) {
    setRoleError(true);
  } else {
    setRoleError(false);
  }

  if (phoneValue.length <= 0) {
    setPhoneError(true);
  } else {
    setPhoneError(false);
  }

  const emailTest = regex.test(emailvalue);
  if (!emailTest) {
    setEmailError(true);
  } else {
    setEmailError(false);
  }

  if (roleValue.length <= 0 || phoneValue.length <= 0 || !emailTest) {
    return false;
  }

  return true;
}
