export default function clickOutsideUserButton(profileButtonRef, setToggleMenu) {
  function clickOutside(event) {
    if (profileButtonRef.current && !profileButtonRef.current.contains(event.target)) {
      setToggleMenu(false);
    }
  }

  document.addEventListener("click", clickOutside);

  return () => {
    document.removeEventListener("click", clickOutside);
  };
}
