import styles from "./DeleteButton.module.css";
import { useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import deleteProduct from "../../../../actions/deleteProduct";
import removeFromCart from "../../../../actions/removeFromCart";
import removeFromFavorites from "../../../../actions/removeFromFavorites";

export default function DeleteButton(props) {
  const { products, product, setProducts } = props;
  const { cart, setCart, favorites, setFavorites } = useContext(AppContext);

  async function handleDelete() {
    if (window.confirm("Vil du slette dette produktet?")) {
      try {
        await deleteProduct(product);
        const newProducts = products.filter((prod) => prod.id !== product.id);
        setProducts(newProducts);
        removeFromCart(product, cart, setCart);
        removeFromFavorites(product, favorites, setFavorites);
      } catch (error) {
        console.log(error);
      }
    }
  }
  return (
    <div className={styles.deleteButton} onClick={() => handleDelete()}>
      <FontAwesomeIcon icon={faTrash} />
    </div>
  );
}
