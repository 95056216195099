import styles from "./OpenFormButton.module.css";
import { useContext } from "react";
import { TopBarContext } from "../../../context/TopBarContext";

export default function OpenFormButton() {
  const { showForm, setShowForm } = useContext(TopBarContext);

  function openForm() {
    setShowForm(true);
  }

  return (
    <div className={`${styles.openFormButton} ${showForm ? "" : styles.active}`} onClick={() => openForm()}>
      Login
    </div>
  );
}
