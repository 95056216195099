import { ref as database_ref, update } from "firebase/database";
import { ref as storage_ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { database, storage } from "../../settings/settings";
import validaton from "./validation";
import DisplayMessage from "../common/DisplayMessage/DisplayMessage";

export default async function submit(event, args) {
  event.preventDefault();
  const bannerImage = event.target.bannerImage.files[0];
  const h1Value = event.target.h1.value.trim();
  const descriptionValue = event.target.description.value.trim();

  const { content, setMessage, setLoading } = args;

  const validated = validaton(event, args);
  if (!validated) return;
  setLoading(true);

  const homeContentRefDB = database_ref(database, `home/content`);
  const homeContentRefStorage = storage_ref(storage, `home/content/bannerImage`);

  try {
    let url = content.bannerUrl;

    if (bannerImage) {
      await uploadBytes(homeContentRefStorage, bannerImage);
      url = await getDownloadURL(homeContentRefStorage);
    }
    await update(homeContentRefDB, { bannerUrl: url, h1: h1Value, description: descriptionValue });

    window.location.reload();
  } catch (error) {
    console.log(error);
    setMessage(<DisplayMessage messageType="error">Oops... noe gikk galt</DisplayMessage>);
  } finally {
    setLoading(false);
  }
}
