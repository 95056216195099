import styles from "./ProfileImage.module.css";
import { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faUser } from "@fortawesome/free-solid-svg-icons";
import { ProfileContext } from "../../context/ProfileContext";
import ImageLoarder from "./ImageLoarder";
export default function ProfileImage() {
  const { userDetails, loading } = useContext(ProfileContext);
  const [previewUrl, setPreviewUrl] = useState(null);

  function handleFileChange(event) {
    if (event.target.files[0]) {
      const url = URL.createObjectURL(event.target.files[0]);
      setPreviewUrl(url);
    }
  }

  return (
    <div className={styles.profileImage}>
      {loading ? <div className={styles.loaderContainer}>{<ImageLoarder />}</div> : ""}
      <label htmlFor="profileImageInput">
        {userDetails.profileImage ? (
          <img src={!previewUrl ? userDetails.profileImage : previewUrl} alt="profile image" />
        ) : (
          <div className={styles.profileIcon}>
            <FontAwesomeIcon icon={faUser} />
          </div>
        )}
        <div className={styles.editImageButton}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </div>
      </label>
      <input type="file" name="profileImage" id="profileImageInput" accept="image/*" defaultValue={userDetails.profileImage} onChange={(event) => handleFileChange(event)} />
    </div>
  );
}
