import { ref as databse_ref, update } from "firebase/database";
import { ref as storage_ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { database, storage } from "../../settings/settings";
import DisplayMessage from "../common/DisplayMessage/DisplayMessage";

export default async function submit(event, setMessage, content, setLoading) {
  event.preventDefault();
  setLoading(true);

  const aboutImage = event.target.aboutImage.files[0];
  const aboutTextValue = event.target.aboutText.value;

  const contentDBRef = databse_ref(database, `about/content`);
  const contentSorageRef = storage_ref(storage, `about/content/aboutImage`);

  if (!aboutImage && !content.url) {
    return <DisplayMessage messageType="warning">Vennligst legg til et bilde</DisplayMessage>;
  }

  try {
    let url = content.url;

    if (aboutImage) {
      await uploadBytes(contentSorageRef, aboutImage);
      url = await getDownloadURL(contentSorageRef);
    }

    await update(contentDBRef, { url: url, text: aboutTextValue });
    window.location.reload();
  } catch (error) {
    console.log(error);
    setMessage(<DisplayMessage messageType="error">Oops... noe gikk galt</DisplayMessage>);
  } finally {
    setLoading(false);
  }
}
