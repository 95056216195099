import styles from "./DecreaseButton.module.css";
import { useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";

export default function DecreaseButton(props) {
  const { cart, setCart } = useContext(AppContext);

  function decrease(id) {
    let newCart = cart;

    const foundItem = cart.find((item) => item.id === id);

    if (foundItem.amount >= 2) {
      foundItem.amount = foundItem.amount - 1;

      setCart([...newCart]);
    }
  }
  return (
    <div className={styles.decreaseButton} onClick={() => decrease(props.item.id)}>
      <FontAwesomeIcon icon={faMinus} />
    </div>
  );
}
