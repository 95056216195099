import styles from "./Cards.module.css";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import ProductCard from "../common/Cards/ProductCard/ProductCard";

export default function Cards() {
  const { favorites, setFavorites } = useContext(AppContext);

  return (
    <div className={styles.cards}>
      {favorites.map((product) => {
        return (
          <div key={product.id}>
            <ProductCard product={product} products={favorites} setFavorites={setFavorites} cardType="favorite" />
          </div>
        );
      })}
    </div>
  );
}
