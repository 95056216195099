import styles from "./UserButton.module.css";
import { useState, useContext, useEffect } from "react";
import { ref, get } from "firebase/database";
import { signOut } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { database, auth } from "../../../settings/settings";
import { AppContext } from "../../../context/AppContext";
import { TopBarContext } from "../../../context/TopBarContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCaretDown } from "@fortawesome/free-solid-svg-icons";

export default function UserButton() {
  const history = useNavigate();

  const { user } = useContext(AppContext);
  const { toggleMenu, setToggleMenu, profileButtonRef } = useContext(TopBarContext);
  const [userDetails, setUserDetails] = useState("");

  useEffect(() => {
    async function fetchUserDetails() {
      try {
        const usersRef = ref(database, `users/${user.auth.currentUser.uid}`);
        const userDetials = await get(usersRef);
        setUserDetails(userDetials.val());
      } catch (error) {
        console.log(error);
      }
    }
    fetchUserDetails();
  }, []);

  function logout() {
    signOut(auth)
      .then(() => {
        history("/");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      {userDetails.profileImage ? (
        <div className={styles.dropDown} onClick={() => setToggleMenu(!toggleMenu)} ref={profileButtonRef}>
          <div className={styles.userButton}>
            <div className={styles.profileImageContainer}>
              <img src={userDetails.profileImage} />
            </div>
          </div>
          <p className={styles.Username}>{userDetails.name.length >= 8 ? `${userDetails.name.slice(0, 8)}...` : userDetails.name}</p>
          <FontAwesomeIcon icon={faCaretDown} />
          <div className={`${styles.menu} ${toggleMenu ? styles.active : ""}`}>
            <li>
              <Link to="/profil">Profil</Link>
            </li>
            <li>
              <Link to="/nytt-produkt">Nytt produkt</Link>
            </li>
            <li>
              <Link to="/nytt-galleri">Nytt galleri</Link>
            </li>
            <li onClick={() => logout()}>Logg ut</li>
          </div>
        </div>
      ) : (
        <div className={styles.userButton}>
          <FontAwesomeIcon icon={faUser} />
        </div>
      )}
    </>
  );
}
