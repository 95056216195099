import styles from "./GalleryForm.module.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ref, get } from "firebase/database";
import { database } from "../../../settings/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import submit from "./submit";
import ButtonLoader from "../../common/Loaders/ButtonLoader";

export default function GalleryForm(props) {
  const [gallery, setGallery] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  let { id } = useParams();

  useEffect(() => {
    if (props.formType === "edit") {
      async function fecthGalleryDB() {
        const gallerysRefDB = ref(database, `gallerys/gallery/${id}`);

        const galleryDB = await get(gallerysRefDB);
        setGallery(galleryDB.val());
      }
      fecthGalleryDB();
    }
  }, []);

  function createPreviewUrl(event) {
    if (event.target.files[0]) {
      const url = URL.createObjectURL(event.target.files[0]);
      setPreviewUrl(url);
    }
  }

  return (
    <form className={styles.form} onSubmit={(event) => submit(event, { formType: props.formType, setMessage, gallery, setPreviewUrl, setLoading })}>
      <label htmlFor="galleryImgInput">
        <div className={styles.imgContainer}>{previewUrl || gallery ? <img src={!previewUrl ? gallery.url : previewUrl} /> : <FontAwesomeIcon icon={faPlus} />}</div>
      </label>
      <input className={styles.galleryImgInput} type="file" name="galleryImg" id="galleryImgInput" accept="image/*" onChange={(event) => createPreviewUrl(event)} />
      {message ? <div className={styles.messageContainer}>{message}</div> : ""}
      <button type="submit">{!loading ? "Legg til galleri" : <ButtonLoader />}</button>
    </form>
  );
}
