import styles from "./FavoritesButton.module.css";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { TopBarContext } from "../../../context/TopBarContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
export default function FavoritesButton() {
  const { favorites, user } = useContext(AppContext);
  const { showForm } = useContext(TopBarContext);

  return (
    <div className={`${styles.favoritesButton} ${!showForm || user ? styles.active : ""}`}>
      <Link to="/favoriter">
        <FontAwesomeIcon icon={faHeart} />
        {favorites.length <= 0 ? "" : <p>{favorites.length}</p>}
      </Link>
    </div>
  );
}
