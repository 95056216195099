import styles from "./Gallery.module.css";
import { useState, useEffect } from "react";
import { GalleryContext } from "../../context/GalleryContext";
import Modal from "./Modal";
import fetchGallerys from "../../actions/fetchGallerys";
import DummyCards from "../common/DummyCards/DummyCards";
import Cards from "./Cards";

export default function Gallery() {
  const [modalUrl, setModalUrl] = useState("");
  const [showModal, setShowmodal] = useState(false);
  const [gallerys, setGallerys] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const gallerysDB = await fetchGallerys();
        setGallerys(gallerysDB);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <GalleryContext.Provider value={{ gallerys, setGallerys, modalUrl, setModalUrl, showModal, setShowmodal, loading }}>
      <main className={styles.main}>
        <Modal />
        <h1 className={styles.h1}>Galleri</h1>
        {gallerys.length <= 0 && !loading ? <p className={styles.noCardsMessage}>Ingen produkter å vise</p> : ""}
        {!loading ? <Cards /> : <DummyCards />}
      </main>
    </GalleryContext.Provider>
  );
}
