import styles from "./InboxButton.module.css";
import { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { Link } from "react-router-dom";
import { database } from "../../../settings/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function InboxButton() {
  const [totalUnReadMails, setTotalUnReadMails] = useState(0);

  const mailLengthsRef = ref(database, "inbox/mailLengths");

  useEffect(() => {
    onValue(mailLengthsRef, (snapshot) => {
      if (snapshot.val()) {
        const { orderMailsUnRead, supportMailsUnRead } = snapshot.val();
        setTotalUnReadMails(orderMailsUnRead + supportMailsUnRead);
      }
    });
  }, []);
  return (
    <div>
      <div className={styles.inboxButton}>
        <FontAwesomeIcon icon={faEnvelope} />
        {!totalUnReadMails <= 0 ? <p>{totalUnReadMails}</p> : ""}
      </div>
    </div>
  );
}
