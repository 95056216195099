import styles from "./Cards.module.css";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { GalleryContext } from "../../context/GalleryContext";
import EditButton from "./EditButton";
import DeleteButton from "./DeleteButton";

export default function Cards() {
  const { user } = useContext(AppContext);
  const { gallerys, setModalUrl, setShowmodal } = useContext(GalleryContext);

  function handleClick(url) {
    setModalUrl(url);
    setShowmodal(true);
  }
  return (
    <div className={styles.cards}>
      {gallerys.map((gallery, index) => {
        return (
          <div key={index} className={styles.card} onClick={() => handleClick(gallery.url)}>
            <div className={styles.actionButton}>
              {user ? <EditButton gallery={gallery} /> : ""}
              {user ? <DeleteButton gallery={gallery} /> : ""}
            </div>
            <img src={gallery.url} alt="" />
          </div>
        );
      })}
    </div>
  );
}
