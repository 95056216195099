export default function clickOutsideNavigation(navigationRef, navigationButtonRef, setShowNavigation) {
  function clickOutside(event) {
    if (navigationButtonRef.current && navigationButtonRef.current.contains(event.target)) {
      return;
    }

    if (!navigationRef.current.contains(event.target)) {
      setShowNavigation(false);
    }
  }

  document.addEventListener("click", clickOutside);

  return () => {
    document.removeEventListener("click", clickOutside);
  };
}
